import React from "react";
import { CommonProps } from "../props/CommonProps";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

interface AutocompleteProps extends CommonProps {
  variant?: any;
  params?: any;
  label?: string;
  type?: any;
  iconVisible?: boolean;
  onChange?: (value: any) => void;
  value?: string;
  className?: string;
}

export const InputText = ({
  sx,
  params,
  variant = "standard",
  label,
  type = "text",
  iconVisible = true,
  onChange,
  value,
  className,
}: AutocompleteProps) => {
  return (
    <Box sx={{ display: "flex", alignItems: "flex-end", position: "relative" }}>
      {iconVisible && (
        <SearchIcon
          sx={{ height: "26px", width: "40px", position: "absolute" }}
        />
      )}

      <TextField
        sx={{ ml: iconVisible ? "40px" : 0 }}
        variant={variant}
        {...params}
        type={type}
        style={{ borderColor: "red" }}
        className={className}
        placeholder={label}
        fullWidth
        value={value}
        InputLabelProps={{
          shrink: false,
        }}
        onChange={(e) => onChange(e)}
      />
    </Box>
  );
};

export default InputText;
