import React from "react"
import PropTypes from "prop-types"
import {Breadcrumbs} from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const Breadcrumb = (props) => {
  return (
    <>
      <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
      >

      </Breadcrumbs>
    </>
  )
}


export default Breadcrumb
