import MuiLink from "@mui/material/Link";
import React from "react";
import { CommonProps } from "../props/CommonProps";

interface LinkProps extends CommonProps {
  underline: "none" | "always" | "hover";
  color: string;
  href: string;
}

export const Link = ({ underline, color, href, children, sx }: LinkProps) => {
  return (
    <MuiLink aria-label="link" sx={sx} underline={underline} color={color} href={href}>
      {children}
    </MuiLink>
  );
};

export default Link;
