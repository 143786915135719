import { create } from "zustand";
import { getOrganizations } from "../services/organizationsService";
import { Organization } from "../model/organization";

interface OrganizationState {
  organizations: Organization[];
  getOrganizations: () => any;
  setOrganizationId: (id: number) => any;
  setApplicationName: (name: string) => any;
  organization_id: number;
  application_name: string;
}

const useOrganizationStore = create<OrganizationState>((set) => ({
  organizations: [],
  organization_id: 0,
  application_name: "",
  getOrganizations: async () => {
    const response = await getOrganizations();
    set({ organizations: response });
  },
  setOrganizationId: async (id: number) => {
    set({ organization_id: id });
  },
  setApplicationName: async (name: string) => {
    set({ application_name: name });
  },
}));

export default useOrganizationStore;
