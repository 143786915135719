import React, { useEffect, useState } from "react";
import Breadcrumbs from "../layout/Breadcrumbs";
import Link from "../layout/Link";
import Button from "../inputs/Button";
import Typography from "../layout/Typography";
import useUserStore from "../store/userStore";
import { addUser } from "../services/userService";
import ResultModal from "../layout/ResultModal";
import InputText from "../inputs/InputText";
import { User } from "../model/user";
import { useRailsContext } from "@shared/utils";

const NewUser = () => {
  const { current_organization } = useRailsContext();
  const users = useUserStore((state) => state.users);
  const getUsers = useUserStore((state) => state.getUsers);
  const [resultTitle, setResultTitle] = useState("");
  const [resultContent, setResultContent] = useState("");
  const [isActionCompleted, setIsActionCompleted] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const [user, setUser] = useState<User>();

  const handleAddUser = () => {
    addUser(user).then((response) => {
      if (response.name !== "AxiosError") {
        window.location.href = `/${current_organization?.id}/users`;
      }
    });
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (
      user &&
      user.first_name &&
      user.last_name &&
      validateEmail(user.email)
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [user]);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <>
      <Breadcrumbs
        sx={{ mb: 10 }}
        breadcrumbs={[
          <Link
            sx={{ fontSize: "24px" }}
            underline="hover"
            key="1"
            color="inherit"
            href={`/${current_organization?.id}/users`}
          >
            Users
          </Link>,
          <Typography
            weight={"bold"}
            size={"24px"}
            key="3"
            color="text.primary"
          >
            Add a new User
          </Typography>,
        ]}
      />
      <div className="mt-5 flex justify-center items-center flex-col">
        <div className="w-[40rem] shadow-md bg-white py-9 px-14 rounded ">
          <p className="text-[#2f6c8b] text-2xl font-bold">Add a new User</p>
          <p className="mt-2">
            Please fill in the required information in the fields below.
          </p>

          <p className="mt-7">First Name</p>
          <InputText
            label="Write the name for the User here"
            iconVisible={false}
            className="!h-12"
            variant="outlined"
            onChange={(e) =>
              setUser((state) => ({ ...state, first_name: e.target.value }))
            }
          />

          <p className="mt-7">Last Name</p>
          <InputText
            label="Write the last name for the User here"
            iconVisible={false}
            className="!h-12"
            variant="outlined"
            onChange={(e) =>
              setUser((state) => ({ ...state, last_name: e.target.value }))
            }
          />

          <p className="mt-7">Email</p>
          <InputText
            label="Write the email for the User here"
            iconVisible={false}
            className="!h-12"
            variant="outlined"
            onChange={(e) =>
              setUser((state) => ({ ...state, email: e.target.value }))
            }
          />
        </div>
        <div className="flex w-[40rem] justify-end gap-x-2 mt-7">
          <Button
            variant="outlined"
            onClick={() => {
              window.location.href = "../";
            }}
            className="!border-[#2f6c8b] !text-[#2f6c8b] !capitalize !border-2 !h-8 !w-28"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={disabled}
            className="!bg-[#2f6c8b] !h-8 !w-28 !capitalize"
            onClick={() => handleAddUser()}
          >
            Save
          </Button>
        </div>
      </div>
      <ResultModal
        title={resultTitle}
        content={resultContent}
        open={isActionCompleted}
        onClose={() => {
          setIsActionCompleted(false);
          window.location.href = `/${current_organization.id}/users`;
        }}
      />
    </>
  );
};

export default NewUser;
