import axios from "axios";
import { User } from "../model/user";
import { Organization } from "../model/organization";

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

const csrfTokenElement = document.querySelector('meta[name="csrf-token"]');
const csrfToken = csrfTokenElement
  ? csrfTokenElement.getAttribute("content")
  : null;

export const getOrganizations = async () => {
  return await axios
    .get(`/organizations.json`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error getting resource:", error);
    });
};

export const generateSecretKey = async () => {
  return await axios
    .get(`organizations/secret_key`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error getting resource:", error);
    });
};

export const selectOrganizationId = async (id: number) => {
  return await axios
    .get(`organizations/set_organization/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error getting resource:", error);
    });
};

export const updateOrganization = async (organization: Organization) => {
  return await axios
    .put(`/organizations/${organization.id}`, organization, {
      headers: {
        "X-CSRF-Token": csrfToken,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error updating resource:", error);
    });
};

export const getCurrentOrganization = async () => {
  return await axios
    .get(`/organizations/current_organization`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error getting resource:", error);
    });
};
