import React, { Fragment } from 'react'
import { Listbox as HeadlessUIListbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

export const ListBox = ({value, label, onChange, options}) => {
  return <HeadlessUIListbox value={value} onChange={onChange} className="inline-block">
    <div className="relative mt-1">
      <HeadlessUIListbox.Button className="cursor-pointer rounded-lg bg-white py-2 pl-6 pr-14 text-left border border-slate-200 focus:outline-none focus-visible:border-primary-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-primary-200">
        <span className="block truncate">{label || "Select..."}</span>
        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </HeadlessUIListbox.Button>
      <Transition as={Fragment}
        leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0"
      >
        <HeadlessUIListbox.Options className="absolute z-10 mt-1 max-h-60 cursor-pointer overflow-auto rounded-md bg-white py-2 text-base shadow-lg ring-1 ring-black/5 focus:outline-none">
          {options.map((option, index) => (
            <HeadlessUIListbox.Option key={index} value={option}
              className={({ active }) => `relative select-none py-3 px-10 ${active ? 'bg-primary-50 text-primary-900' : 'text-gray-900'}`}
            >
              {({ selected }) => (
                <>
                  <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                    { typeof option === "string" ? option : option.label || option.name || option.title || option.id }
                  </span>
                  {selected &&
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary-500">
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  }
                </>
              )}
            </HeadlessUIListbox.Option>
          ))}
        </HeadlessUIListbox.Options>
      </Transition>
    </div>
  </HeadlessUIListbox>
}

