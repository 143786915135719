import React from "react";
import { labelize } from "@shared/utils";
import { Form, FormField, ToggleField, useFormContext } from "@shared/form_fields";

const CustomFieldsTab = ({ entity, custom_fields, ajax }) => {

  return <div className="p-8 pt-2 max-w-2xl">
    <Form instance={entity} action="edit" ajax={true} onSubmit={ajax.refresh} disableOnSubmit={false}>
      <CustomFieldsForm custom_fields={custom_fields} />
    </Form>
  </div>
};

const CustomFieldsForm = ({ custom_fields }) => {
  const { readOnly, reset } = useFormContext();

  return <>
    {custom_fields?.map(field =>
        <span key={field.name}>
          {field.field_type == "toggle" ?
              <ToggleField field={`custom_fields.${field.name}`} label={labelize(field.name)} />
            :
              <FormField field={`custom_fields.${field.name}`} label={labelize(field.name)} />
          }
        </span>
      )}

      <div className="flex gap-2 mt-8">
        <button type="button" className="inline-block w-44 rounded-lg py-2 px-8 bg-gray-200 hover:bg-gray-300 font-medium"
          disabled={readOnly}
          onClick={reset}
        >
          Cancel
        </button>
        <button type="submit" className="inline-block w-44 rounded-lg py-2 px-8 bg-primary hover:bg-primary-600 text-white font-medium"
          disabled={readOnly}
        >
          Save
        </button>
      </div>
  </>
}

export default CustomFieldsTab;
