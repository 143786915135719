import { useMemo, useState, useRef, useEffect, useCallback } from 'react';
import { deepEqual } from './utils';
import { set } from 'date-fns';

/**
 * Usage:
 * const [stagedValue, setStagedValue, commit] = useStagedState(initialValue);
 */
export function useStagedState<T>(value: T, onCommit?: (value: T) => void): {
  stagedValue: T;
  setStagedValue: React.Dispatch<React.SetStateAction<T>>;
  commit: () => void;
  setAndCommit: (newValue: T) => void;
  reset: () => void;
  isChangePending: boolean;
} {
  const [stagedValue, setStagedValue] = useState(value);
  const isChangePending = useMemo(() =>
    !deepEqual(stagedValue, value)
  , [stagedValue, value]);

  function commit() {
    if (isChangePending) {
      onCommit && onCommit(stagedValue);
    }
  }

  function setAndCommit(newValue: T) {
    setStagedValue(newValue);
    if (!deepEqual(newValue, value)) {
      onCommit && onCommit(newValue);
    }
  }

  function reset() {
    setStagedValue(value);
  }

  return {
    stagedValue,
    setStagedValue,
    commit,
    setAndCommit,
    reset,
    isChangePending
  };
}

export function useScrollDirection(buffer: number = 0) {
  const containerRef = useRef(null);
  const [scrollDirection, setScrollDirection] = useState({ hasLeftScroll: false, hasRightScroll: false });

  useEffect(() => {
    const checkScrollDirection = () => {
      if (containerRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
        const hasLeftScroll = scrollLeft > buffer;
        const hasRightScroll = scrollWidth > (scrollLeft + clientWidth + buffer);
        setScrollDirection({ hasLeftScroll, hasRightScroll });
      }
    };

    const container = containerRef.current;

    // Setup resize observer to re-check scroll direction when container size changes
    const resizeObserver = new ResizeObserver(checkScrollDirection);
    if (container) {
      resizeObserver.observe(container);
    }

    // Check scroll direction on mount, on scroll, and on resize events
    checkScrollDirection();
    container.addEventListener('scroll', checkScrollDirection);

    // Cleanup
    return () => {
      if (container) {
        container.removeEventListener('scroll', checkScrollDirection);
        resizeObserver.unobserve(container);
      }
    };
  }, []);

  return {
    ref: containerRef,
    hasLeftScroll: scrollDirection.hasLeftScroll,
    hasRightScroll: scrollDirection.hasRightScroll
  };
}

/**
 * Hook to manage state for an object with the ability to set individual fields one at a time.
 *
 * @param initialState
 * @returns [state, setFields]
 */
export function useObjectState(initialState) {
  const [state, setState] = useState(initialState);

  const setFields = useCallback((newFields) => {
    setState(prevState => ({
      ...prevState,
      ...newFields
    }));
  }, []);

  return [state, setFields];
}

