import React from "react";

interface ErrorMessages {
  [key: string]: string[];
}

interface RailsErrorsProps {
  errors: { error: ErrorMessages } | ErrorMessages | string;
}

const RailsErrors: React.FC<RailsErrorsProps> = ({ errors }) => {
  if (!errors) {
    return null;
  }

  const prettifyErrorField = (field: string) => {
    const tmpField = field.replace("_", " ")
    const tmpFieldWords = tmpField.split(" ")
    let prettifiedField = ''
    for (const word of tmpFieldWords) {
      prettifiedField = prettifiedField.concat(`${word.charAt(0).toUpperCase()}${word.slice(1)} `)
    }
    return prettifiedField.slice(0, -1)
  }

  return (
    <div className="py-1 relative" role="alert">
      {typeof errors === "object" ?
        Object.entries(errors.error || errors).map(([key, messages]) => (
          <div key={key} className="mt-2">
            <ul className="list-disc pl-5">
              {messages.map((message, index) => (
                <li key={index}>
                  <strong>{prettifyErrorField(key)}</strong>&nbsp;{message}
                </li>
              ))}
            </ul>
          </div>
        ))
      :
        <div className="mt-2">
          <h4 className="font-bold">Error</h4>
          <ul className="list-disc pl-5">
            <li>{errors}</li>
          </ul>
        </div>
      }
    </div>
  );
}

export default RailsErrors;
