import React, { useMemo } from "react";
import { daysToBitmask, daysToString, organizationUrlFor } from "@shared/utils";
import { useObjectState } from "@shared/hooks";
import { EditFormModalButton, NewFormModalButton } from "@shared/modal";
import { DeleteButton } from "@shared/form_fields";
import { AutoTable } from "@shared/tables/auto_table";
import { Column, TableEmpty } from "@shared/tables/table";
import { format, toZonedTime } from "date-fns-tz";

const RegularSchedulesTab = ({ ajax }) => {
  const [params, setParams] = useObjectState({
    sortBy: "start_time",
    sortOrder: "asc",
    page: 1,
    perPage: 50,
    search: ""
  });
  const { sortBy, sortOrder, page, perPage, search } = params;
  const { data: schedule } = ajax;
  const { regular_schedules } = schedule;

  const filteredRegularSchedules = useMemo(() => {
    if (!search) return regular_schedules;
    return regular_schedules.filter(regular_schedule => regular_schedule.name.toLowerCase().includes(search.toLowerCase()));
  }, [ajax.data, search]);

  return <>
    <div id="regular_schedules" className="flex justify-between px-8 py-6">
      <div className="flex grow">
        {/* <Search value={search} items={regular_schedules.map((prompt) => prompt.name)} onChange={(search) => setParams({ search })} className="z-10 w-4/5 md:w-3/5" /> */}
      </div>

      <div>
        <NewFormModalButton size="xl" className="pl-3" label="New Regular Schedule" ajax={true} onSubmit={ajax.refresh} closeOnSubmit={true}
          model="regular_schedule" template="regular_schedules/form" formAction={organizationUrlFor("regular_schedules")}
          props={{ schedule_id: schedule.id }}
        />
      </div>
    </div>

    <AutoTable data={filteredRegularSchedules} loading={ajax.loading}
      sortBy={sortBy} sortOrder={sortOrder}
      onSortChanged={(sortBy, sortOrder) => { setParams({ sortBy, sortOrder }) }}
      currentPage={page} pageSize={perPage}
      onPageChanged={page => setParams({ page })}
    >
      <TableEmpty>
        <p className="p-6 text-gray-900"><i>No regular schedules found.</i></p>
      </TableEmpty>

      <Column title="Days" field="days" sortFn={r => daysToBitmask(r.days)}>
        {regular_schedule => daysToString(regular_schedule.days)}
      </Column>
      <Column title="Start Time" field="start_time" sortField="start_time">
        {regular_schedule => format(toZonedTime(new Date(regular_schedule.start_time), "UTC"), "h:mm a")}
      </Column>
      <Column title="End Time" field="end_time" sortField="end_time">
        {regular_schedule => format(toZonedTime(new Date(regular_schedule.end_time), "UTC"), "h:mm a")}
      </Column>

      <Column sortable={false} style={{ width: "1%" }}>
        {regular_schedule =>
          <div className="flex gap-3 items-center">
            {/* You'll want to check for actual permissions for this */}
            <EditFormModalButton size="xl" instance={regular_schedule} className="w-24"
              ajax={true} onSubmit={ajax.refresh} closeOnSubmit={true}
              props={{ schedule_id: schedule.id }}
            />
            <DeleteButton instance={regular_schedule} className="w-24" ajax={true} onDelete={ajax.refresh}
              instanceName={daysToString(regular_schedule.days)}
            />
          </div>
        }
      </Column>

    </AutoTable>
  </>
};

export default RegularSchedulesTab;
