import Flash from "@shared/flash";
import { Image } from "@shared/html_tags";
import React from "react";

const LoginFlowCard = ({ children, error, icon }) => {
  return (
    <div className="flex flex-col gap-4 bg-white rounded-2xl m-auto p-12 w-5/6 sm:w-[38rem]">
      {icon || (
        <div className="text-[#20b094] m-auto font-semibold w-full text-center text-3xl md:text-4xl">
          <Image src="cloverhound-logo.png" className="mr-2 md:mr-3 w-[45px] md:w-[55px] pb-1 m-auto inline-block" />
          <span>Cloverhound</span>
        </div>
      )}
      {error && (
        <div className="flex items-center text-red-500 h-12">{error}</div>
      )}
      <Flash />
      <div className="flex flex-col gap-6">{children}</div>
    </div>
  );
};
export default LoginFlowCard;
