import React from "react";
import { FormField, HiddenField, SelectField, ToggleField } from "@shared/form_fields";

export default function FeatureFlagsForm() {
  return (
    <>
      <FormField field="key" readOnly={true} />
      <HiddenField field="key" />
      <ToggleField field="value" />
      {/* <SelectField field="value" options={["true", "false"]} /> */}
    </>
  )
}
