import React from "react";
import { Checkbox, CheckboxField, FormField, HiddenField, useFormContext } from "@shared/form_fields";

export default function UsersForm() {
  const { action, instance } = useFormContext();

  const [authMethods, setAuthMethods] = React.useState(instance.auth_methods || []);

  const handleAuthMethodsChange = (e) => {
    if (e.target.checked) {
      setAuthMethods([...authMethods, e.target.value]);
    } else {
      setAuthMethods(authMethods.filter((method) => method != e.target.value));
    }
  }

  return (
    <>
      <FormField field="full_name" />
      <FormField field="email" readOnly={action == "new" ? false : true} />

      {authMethods.map((method, i) => (
        <HiddenField key={i} field="auth_methods" asArray={true} value={method} />
      ))}

      <Checkbox name="auth_methods_webex" value="webex" checked={authMethods.includes("webex")} onChange={handleAuthMethodsChange}
        label="Allow Login via Webex"
      />
      <Checkbox name="auth_methods_password" value="password" checked={authMethods.includes("password")} onChange={handleAuthMethodsChange}
        label="Allow Login with Password"
      />

    </>
  )
}
