import React, { useState } from 'react';
import { Switch } from '@headlessui/react';
import { Modal } from './modal';

const ToggleWithConfirmation = ({ instance, fieldName, fieldLabel, checked, onConfirm, getConfirmationMessage, getButtonLabel }) => {
  const [show, setShow] = useState(false);

  return <>
    <Modal show={show}>
      <section className="modal-body p-8">
        {getConfirmationMessage ? getConfirmationMessage(checked)
          :
          <span>Are you sure you want to <strong>{checked ? 'disable' : 'enable'}</strong> {fieldLabel} for "{instance.name}"?</span>
        }
      </section>

      <section className="modal-footer p-4 flex justify-stretch gap-2">
        <button type="button" disabled={!show} onClick={() => setShow(false)} className="rounded-lg py-2 px-8 bg-gray-200 hover:bg-gray-300 text-black block font-medium w-full">
          Cancel
        </button>
        <button type="button" disabled={!show} className="rounded-lg py-2 px-8 bg-primary hover:bg-primary-600 text-white block font-medium w-full"
          onClick={() => {
            onConfirm && onConfirm();
            setShow(false);
          }}
        >
          {getButtonLabel ? getButtonLabel(checked)
            :
            <span>{checked ? "Disable" : "Enable"} {fieldLabel}</span>
          }
        </button>
      </section>
    </Modal>

    <Switch id={fieldName} checked={checked}
        onChange={(checked) => {
          setShow(true);
        }}
        className={`${checked ? 'bg-primary-600' : 'bg-gray-600'}
          relative inline-flex h-[20px] w-[42px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
    >
      <span className="sr-only">{instance.name} {fieldLabel} enabled</span>
      <span
        aria-hidden="true"
        className={`${checked ? 'translate-x-[18px]' : 'translate-x-0'}
          pointer-events-none inline-block h-[16px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
      />
    </Switch>
  </>
}

export default ToggleWithConfirmation;
