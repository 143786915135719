import React from "react";

const EntityStatus = ({entity}) => {
  return (
    <div className="flex items-center gap-2">
      <svg className={entity.status?.is_open ? "fill-green-600" : "fill-red-600"}
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="5" cy="5" r="5" />
      </svg>
      <span className="capitalize">
        {entity.status?.state == "force_closed" ? "closed" : entity.status?.state}
      </span>
    </div>
  );
};

export default EntityStatus;
