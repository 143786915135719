import React from "react";
import { useRailsContext } from "@shared/utils";

export default function Flash() {
  const railsContext = useRailsContext();

  return (
    <>
      {railsContext.flash.notice &&
        <p className="py-2 px-3 bg-green-50 mb-5 text-green-500 font-medium rounded-lg inline-block" id="notice">{railsContext.flash.notice}</p>
      }
      {railsContext.flash.alert &&
        <p className="py-2 px-3 bg-red-50 mb-5 text-red-500 font-medium rounded-lg inline-block" id="alert">{railsContext.flash.alert}</p>
      }
      {railsContext.devise_errors &&
        Object.keys(railsContext.devise_errors).map((error, index) => {
          return (
            <p key={index} className="py-2 px-3 bg-red-50 mb-5 text-red-500 font-medium rounded-lg inline-block" id="alert">{error} {railsContext.devise_errors[error]}</p>
          )
        })
      }
    </>
  );
}
