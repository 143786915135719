import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import Modal from "../layout/Modal";
import { updateUser } from "../services/userService";
import ResultModal from "../layout/ResultModal";
import { User } from "../model/user";

const EditUserModal = ({ open, onClose, userSelected }) => {
  const [user, setUser] = useState<User>();

  const [resultTitle, setResultTitle] = useState("");
  const [resultContent, setResultContent] = useState("");
  const [isActionCompleted, setIsActionCompleted] = useState(false);

  useEffect(() => {
    setUser(userSelected);
  }, [userSelected]);

  const handleEdit = () => {
    updateUser(user).then((response) => {
        window.location.reload();
    });
  };

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      onSubmit={() => handleEdit()}
      title="Edit User"
      width={40}
      children={
        <>
          <p className="mt-7">First Name</p>
          <TextField
            id="outlined-basic"
            label=""
            fullWidth
            size="small"
            value={user?.first_name}
            onChange={(e) => setUser({ ...user, first_name: e.target.value })}
          />
          <p className="mt-7">Last Name</p>
          <TextField
            id="outlined-basic"
            label=""
            fullWidth
            size="small"
            value={user?.last_name}
            onChange={(e) => setUser({ ...user, last_name: e.target.value })}
          />
          <p className="mt-7">Email</p>
          <TextField
            id="outlined-basic"
            label=""
            fullWidth
            size="small"
            value={user?.email}
            onChange={(e) => setUser({ ...user, email: e.target.value })}
          />
          <ResultModal
            title={resultTitle}
            content={resultContent}
            open={isActionCompleted}
            onClose={() => {
              setIsActionCompleted(false);
            }}
          />
        </>
      }
    />
  );
};

export default EditUserModal;
