import React, { StrictMode } from "react";
import ReactRailsUJS from "react_ujs"
import { RailsContext } from "./rails_context";

export default function RailsComponent({component, props, context}) {
  const ViewComponent = ReactRailsUJS.getConstructor(component);

  return (
    // <StrictMode>
      <RailsContext.Provider value={context}>
        <ViewComponent {...props} />
      </RailsContext.Provider>
    // </StrictMode>
  )
}
