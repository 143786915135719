import React, { useEffect, useState } from "react";
import { Autocomplete, Snackbar, TextField } from "@mui/material";
import { Settings as SettingsIcon, ContentCopy as ContentCopyIcon, FormatListBulleted as FormatListBulletedIcon } from "@mui/icons-material";
import Modal from "./layout/Modal";
import ResultModal from "./layout/ResultModal";
import Button from "./inputs/Button";
import Dropdown from "./inputs/Dropdown";
import {
  generateSecretKey,
  getOrganizations,
  updateOrganization,
} from "./services/organizationsService";
import useOrganizationStore from "./store/organizationStore";
import { Organization } from "./model/organization";
import {
  ApplicationNames,
  DefaultApplicationName,
} from "./constants/AppplicationNames";
import { useRailsContext } from "@shared/utils";

const Settings = (props) => {
  const railsContext = useRailsContext();

  const [closeSnackBar, setCloseSnackBar] = useState<boolean>(false);
  const [organization, setOrganization] = useState<Organization>();
  const [apiKeyValue, setApiKeyValue] = useState<string>("");

  const [resultTitle, setResultTitle] = useState("");
  const [resultContent, setResultContent] = useState("");
  const [isActionCompleted, setIsActionCompleted] = useState<boolean>(false);
  const [timeZone, setTimeZone] = useState<string | undefined>("");
  const [prevTimeZone, setPrevTimeZone] = useState("");
  const [currentApplicationName, setCurrentApplicationName] = useState<
    number | undefined
  >(1);
  const [isDirtyForm, setIsDirtyForm] = useState<boolean>(false);
  const [isDirtyFormName, setIsDirtyFormName] = useState<boolean>(false);

  const [openOrgNameModal, setOpenOrgNameModal] = useState<boolean>(false);
  const [openApiKeyModal, setOpenApiKeyModal] = useState<boolean>(false);
  const [openTimeZoneModal, setOpenTimeZoneModal] = useState<boolean>(false);
  const [openOrgApplicationNameModal, setOpenOrgApplicationNameModal] =
    useState<boolean>(false);

  const organizations = useOrganizationStore((state) => state.organizations);
  const [organizationId, setOrganizationId] = useState(
    railsContext.current_organization?.id,
  );
  
  useEffect(() => {
    getOrganizations()
  }, []);

  useEffect(() => {
    const currentOrganization = organizations?.find(
      (o) => o.id === organizationId,
    );
    setOrganization((state: any) => ({
      ...state,
      name: currentOrganization?.name,
      id: organizationId,
      secret_access_key: currentOrganization?.secret_access_key,
      application_name: currentOrganization?.application_name,
      time_zone: currentOrganization?.time_zone,
    }));
    setApiKeyValue(
      currentOrganization?.secret_access_key
        ? currentOrganization?.secret_access_key
        : "",
    );
    setTimeZone(currentOrganization?.time_zone);
    if (currentOrganization) {
      if (currentOrganization.application_name) {
        setCurrentApplicationName(
          ApplicationNames.find(
            (a) => a.name === currentOrganization?.application_name,
          )?.id,
        );
      } else {
        setCurrentApplicationName(DefaultApplicationName().id);
      }
    }
  }, [organizationId, organizations]);

  const updateOrganizationName = () => {
    if (organization) {
      setOrganization((state: any) => ({
        ...state,
        time_zone: organization?.time_zone,
      }));
      organization.time_zone = organization?.time_zone;
      updateOrganization(organization).then(() => {
        window.location.reload();
      });
    }
  };

  const generateApiKey = () => {
    generateSecretKey().then((response) => {
      setApiKeyValue(response);
      updateOrganization({
        id: organization?.id,
        secret_access_key: response,
      }).then(() => {});
    });
  };

  return (
    <>
      <div>
        <p className="mt-7">Organization Name</p>
        <div className="md:flex items-center">
          <TextField
            id="outlined-basic"
            label=""
            className="!w-[100%] md:!w-[308px] md:!mr-10"
            fullWidth
            size="small"
            value={organization?.name}
            onChange={(e) => {
              setOrganization((state: any) => ({
                ...state,
                name: e.target.value,
              }));
              setIsDirtyForm(true);
            }}
          />
          <div className="flex justify-end mt-4 md:mt-0">
            <Button
              variant="contained"
              disabled={!isDirtyForm}
              onClick={(e) => {
                setOpenOrgNameModal(!openOrgNameModal);
              }}
              className="!bg-[#2f6c8b] !h-10 !w-[100%] md:!w-28 !capitalize"
            >
              Update
            </Button>
          </div>
        </div>
        <p className="mt-7">Displayed Application Name</p>
        <div className="md:flex items-center">
          <Dropdown
            className="!w-[100%] md:!w-[308px] md:!mr-10 !h-8"
            data={ApplicationNames}
            sx={{ height: "40px" }}
            label="name"
            value="id"
            selectedValue={currentApplicationName}
            onChange={(e) => {
              setCurrentApplicationName(e);
              setOrganization((state: any) => ({
                ...state,
                application_name: ApplicationNames.find((a) => a.id === e)?.name,
              }));
              setIsDirtyFormName(true);
            }}
          />
          <div className="flex justify-end mt-4 md:mt-0">
            <Button
              variant="contained"
              disabled={!isDirtyFormName}
              onClick={(e) => {
                setOpenOrgApplicationNameModal(!openOrgApplicationNameModal);
              }}
              className="!bg-[#2f6c8b] !h-10 !w-[100%] md:!w-28 !capitalize"
            >
              Update
            </Button>
          </div>
        </div>

        <p className="mt-7">API Key</p>
        <div className="md:flex items-center">
          <TextField
            className="!mr-1 !w-[88%] md:!w-[308px]"
            value={apiKeyValue}
            label=""
            size="small"
          />
          <ContentCopyIcon
            onClick={() => {
              navigator.clipboard.writeText(organization?.secret_access_key);
              setCloseSnackBar(!closeSnackBar);
            }}
            className="!mr-0 md:!mr-5 text-[#2e6c8b] !text-lg cursor-pointer"
          />
          <Snackbar
            autoHideDuration={1000}
            open={closeSnackBar}
            onClose={() => setCloseSnackBar(false)}
            message="Copied!"
          />
          <div className="mt-4 md:mt-0">
            <Button
              variant="contained"
              className="!bg-[#2f6c8b] !h-10 !w-[100%] md:!w-40 !capitalize"
              onClick={() => setOpenApiKeyModal(!openApiKeyModal)}
            >
              Generate API Key
            </Button>
          </div>
        </div>
        <p className="mt-8">Selected timezone:</p>

        <Autocomplete
          options={props.time_zones}
          isOptionEqualToValue={(option, value) => option.value === value?.value}
          value={
            props.time_zones.filter((my_tz) => my_tz.value === timeZone)[0] ?? {
              name: "America/New_York",
              label: "America/New_York (GMT-05:00)",
            }
          }
          onChange={(event: any, newValue: any | null) => {
            setPrevTimeZone(timeZone);
            setTimeZone(newValue?.value);
            setOrganization((state: any) => ({
              ...state,
              time_zone: newValue?.value,
            }));
            setOpenTimeZoneModal(!openTimeZoneModal);
          }}
          sx={{ width: 350 }}
          renderInput={(params) => <TextField {...params} />}
        />
        <div className="mt-8">
          <Button
            variant="contained"
            className="!bg-[#2f6c8b] !h-10 !w-[100%] md:!w-44 !capitalize"
            onClick={() =>
              (window.location.href = `/${railsContext.current_organization?.id}/custom_fields`)
            }
          >
            Edit Custom Fields
          </Button>
        </div>

        <ResultModal
          title={resultTitle}
          content={resultContent}
          open={isActionCompleted}
          onClose={() => {
            setIsActionCompleted(false);
          }}
        />

        <Modal
          okText="Update"
          content=""
          onClose={() => setOpenOrgNameModal(false)}
          onSubmit={() => {
            updateOrganizationName();
          }}
          open={openOrgNameModal}
          title="Update Organization Name"
          height={15}
        >
          <p>Are you sure you want to update Organization name?</p>
        </Modal>

        <Modal
          okText="Update"
          content=""
          onClose={() => setOpenOrgApplicationNameModal(false)}
          onSubmit={() => {
            updateOrganizationName();
          }}
          open={openOrgApplicationNameModal}
          title="Update Application Name"
          height={15}
        >
          <p>Are you sure you want to update the Application name?</p>
        </Modal>

        <Modal
          okText="Generate"
          content=""
          onClose={() => setOpenApiKeyModal(false)}
          onSubmit={() => {
            generateApiKey();
            setOpenApiKeyModal(false);
          }}
          open={openApiKeyModal}
          title="Generate new API Key"
          height={15}
        >
          <p>Are you sure you want to generate a new API Key?</p>
        </Modal>

        <Modal
          okText="Yes"
          content=""
          onClose={() => {
            setTimeZone(prevTimeZone);
            setOrganization((state: any) => ({
              ...state,
              time_zone: prevTimeZone,
            }));
            setOpenTimeZoneModal(false);
          }}
          onSubmit={() => {
            setTimeZone(timeZone);
            updateOrganization(organization).then(() => {});
            setOpenTimeZoneModal(false);
          }}
          open={openTimeZoneModal}
          title="Select Time Zone"
          height={15}
        >
          <p>Are you sure you want to change the Time Zone?</p>
        </Modal>
      </div>
    </>
  );
};

export default Settings;
