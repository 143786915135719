import React, { useEffect } from "react";
import { DataGrid, GridFilterModel } from "@mui/x-data-grid";

interface TableDataGridProps {
  rows?: any;
  columns?: any;
  height?: any;
  filterModel?: any;
  sort?: any;
  columnVisibilityModel?: any;
}

export const TableDataGrid = ({
  rows,
  columns,
  height,
  filterModel,
  sort,
  columnVisibilityModel,
}: TableDataGridProps) => {
  const [currentFilterModel, setFilterModel] =
    React.useState<GridFilterModel>(filterModel);

  useEffect(() => {
    setFilterModel(filterModel);
  }, [filterModel]);

  return (
    <div style={{ height: height, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        disableColumnSelector={true}
        filterModel={currentFilterModel}
        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
        disableRowSelectionOnClick
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 15 },
          },
          sorting: {
            sortModel: sort,
          },
          columns: {
            columnVisibilityModel: columnVisibilityModel,
          },
        }}
        pageSizeOptions={[15, 30, 60]}
      />
    </div>
  );
};

export default TableDataGrid;
