import React, { useState } from "react";
import { useForm } from "react-hook-form";
import LoginFormWrapper from "../components/login-flow-wrapper";
import FormLabel from "../components/form-label";
import LoginFlowLinks from "../components/login-flow-links";
import LoginFlowCard from "../components/login-flow-card";
import { Button } from "@shared/form_fields";
import { useRailsContext } from "@shared/utils";

const ForgotPasswordForm = ({ onSuccess }) => {
  const { csrf_token } = useRailsContext();

  const [error, setError] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = handleSubmit(async (data) => {
    const formData = new FormData();
    formData.append('user[email]', data.email);
    formData.append('commit', "Send me reset password instructions");

    fetch("/users/password", {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrf_token,
        "X-Requested-With": "XMLHttpRequest"
      },
      body: formData
    })
      .then((response) => {
        if (response.ok) {
          onSuccess();
        } else {
          throw new Error("Request failed");
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        setError(error.message);
      });
  });

  return (
    <LoginFormWrapper>
      <LoginFlowCard error={error}>
        <div className="text-2xl font-semibold text-gray-500">
          Forgot your password?
        </div>
        <form onSubmit={onSubmit} className="flex flex-col gap-4">
          <div className="flex flex-col gap-0">
            <FormLabel label="Email" />
            <input
              type="email"
              name="email"
              className="form-control rounded-md"
              placeholder="Email"
              {...register("email")}
            />
          </div>

          <div className="mt-2">
            <Button type="submit" className="bg-sky-700 w-full">Send me password reset instructions</Button>
          </div>

          <LoginFlowLinks currentView="new-password" />
        </form>
      </LoginFlowCard>
    </LoginFormWrapper>
  );
};
export default ForgotPasswordForm;
