import React from "react";
import { twMerge } from "tailwind-merge";

export const Card = ({ children, className }) => {
  return (
    <div className={twMerge(`bg-white shadow-md rounded-xl`, className)}>
      {children}
    </div>
  );
}
