import React from "react";
import Flash from "@shared/flash";
import { Card } from "@shared/card";
import { Tabs, Tab } from "@shared/tabs";
import { IndexLink } from "@shared/html_tags";

export default function GroupShow({group}) {

  return (
    <>
      <Flash />

      <div className="flex justify-between items-center">
        <h1 className="font-semibold text-2xl text-slate-700">
          Group: {group.name}
        </h1>
      </div>

      <IndexLink to="groups" className="block mt-1 mb-4 font-normal text-sm">&larr; Back to Groups</IndexLink>

      <Card className="mt-3">
        <Tabs className="">
          <Tab title="Details">
            <div className="p-6">
              <dl className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div>
                  <dt className="font-semibold">Name</dt>
                  <dd>{group.name}</dd>
                </div>
              </dl>
            </div>
          </Tab>
        </Tabs>
      </Card>
    </>
  )
}
