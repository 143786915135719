import React from "react";

const LoginFlowWrapper = ({ children }) => {
  return (
    <div className="min-h-screen flex flex-col justify-center gap-4 bg-primary-500">
      {children}
    </div>
  );
};
export default LoginFlowWrapper;
