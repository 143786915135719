import React, { useMemo, useState } from "react";
import { Switch } from "@headlessui/react";
import { EditFormModalButton, NewFormModalButton } from "@shared/modal";
import { DeleteButton } from "@shared/form_fields";
import { TableEmpty, Column } from "@shared/tables/table";
import { AutoTable } from "@shared/tables/auto_table";
import { Card } from "@shared/card";
import { useQueryParams } from "@shared/filter_sort_paginate";
import { useAjax } from "@shared/ajax";
import { Search } from "@shared/search";
import ToggleWithConfirmation from "@shared/toggle-with-confirmation";

export default function GroupIndex({ data, custom_fields }) {

  const [params, setParams] = useQueryParams({
    sort_by: { default: "name" },
    sort_order: { default: "asc" },
    page: { type: "number", default: 1 },
    per_page: { type: "number", default: 50 },
    filters: { type: "object", default: [] },
    search: { type: "string", default: "" }
  });

  const {
    sort_by,
    sort_order,
    page,
    per_page,
    filters,
    search
  } = params;

  // useEffectAfterMount(() => {
  //   setParams({ page: 1 });
  // }, [filters, start, end]);


  const ajax = useAjax(data);
  const groups = ajax.data.items;

  const filteredGroups = useMemo(() => {
    if (!search) return groups;
    return groups.filter(group => group.name.toLowerCase().includes(search.toLowerCase()));
  }, [ajax.data, search]);

  // const setFilters = (newFilters) => setParams({ filters: newFilters });

  return (
    <>
      {/* <Flash /> */}

      <div className="flex justify-between items-center">
        <h1 className="font-semibold text-2xl text-slate-700">
          Groups
        </h1>
      </div>

      <Card className="mt-3 pb-6">
        <div id="groups" className="flex justify-between p-8">
          <div className="flex grow">
            <Search value={search} items={groups.map((group) => group.name)} onChange={(search) => setParams({search})} className="z-10 w-4/5 md:w-3/5" />
          </div>

          <div>
            <NewFormModalButton className="pl-3" label="New Group" ajax={true} onSubmit={ajax.refresh} closeOnSubmit={true}
              props={{custom_fields}} />
          </div>
        </div>

        <AutoTable data={filteredGroups} loading={ajax.loading}
            sortBy={sort_by} sortOrder={sort_order}
            onSortChanged={(sortBy, sortOrder) => { setParams({ sort_by: sortBy, sort_order: sortOrder }) }}
            currentPage={page} pageSize={per_page}
            onPageChanged={page => setParams({ page })}
        >
          <TableEmpty>
            <p className="p-6 text-gray-900"><i>No groups found.</i></p>
          </TableEmpty>

          <Column title="Name" field="name" blankValue="-" />

          {custom_fields?.filter(field => field.active === true).map(field =>
            <Column key={field.name} title={field.label} blankValue="-" field={`custom_fields.${field.name}`}>
              {group => {
                if (field.field_type == "toggle") {
                  return <ToggleWithConfirmation instance={group} fieldName={field.name} fieldLabel={field.label}
                    checked={group.custom_fields && group.custom_fields[field.name]}
                    onConfirm={() => ajax.update(group, {
                      custom_fields: { [field.name]: !group.custom_fields[field.name] }
                    })}
                  />
                }

                return <span>
                  {(group.custom_fields &&
                    group.custom_fields[field.name])}
                </span>
              }}
            </Column>
          )}

          <Column sortable={false} style={{width: "1%"}}>
            {group =>
              <div className="flex gap-3 items-center">
                {/* You'll want to check for actual permissions for this */}
                <EditFormModalButton instance={group} className="w-24"
                  ajax={true} onSubmit={ajax.refresh} closeOnSubmit={true}
                  props={{custom_fields}}
                />
                <DeleteButton instance={group} className="w-24" ajax={true} onDelete={ajax.refresh} />
              </div>
            }
          </Column>

        </AutoTable>

      </Card>
    </>
  )
}
