import React, { useState } from "react";
import { FormField, CheckboxField, SelectField, useFormContext, Checkbox, HiddenField, ToggleField } from "@shared/form_fields";
import { format, toZonedTime } from "date-fns-tz";
import MiniCalendar from "@shared/mini_calendar";

export default function SpecialScheduleForm({holiday_list_id}) {
  const { instance } = useFormContext();

  let initialDate = instance.date ? new Date(instance.date) : new Date();
  const [date, setDate] = useState(format(toZonedTime(initialDate, "UTC"), "yyyy/MM/dd"));

  return (
    <>
      <HiddenField field="holiday_list_id" value={holiday_list_id} />

      <FormField field="name" label="Name" />

      <MiniCalendar selectedDate={date} onChange={newDate => setDate(format(toZonedTime(newDate, "UTC"), "yyyy/MM/dd"))} />
      <HiddenField field="date" value={date} />
    </>
  )
}


