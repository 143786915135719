import React from "react"
const Edit = () => {

    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.324 4.03351L11.6869 0.191061C11.5664 0.0636871 11.4257 0 11.2649 0C11.1042 0 10.9434 0.0636871 10.843 0.191061L1.19778 10.4022C1.1174 10.4871 1.05712 10.5933 1.03702 10.7206L0.0122223 15.6245C-0.027966 15.8368 0.0323165 16.0491 0.172976 16.2189C0.293541 16.3463 0.434199 16.41 0.594953 16.41C0.635141 16.41 0.675329 16.41 0.715518 16.3888L5.35727 15.3061C5.47783 15.2849 5.5783 15.2212 5.65868 15.1363L15.324 4.94635C15.4445 4.81898 15.5048 4.67038 15.5048 4.50054C15.5048 4.30948 15.4445 4.13965 15.324 4.03351ZM2.46371 10.848L9.11487 3.82122L11.8879 6.75082L5.2367 13.7776L2.46371 10.848ZM1.96136 12.1217L4.03105 14.3083L1.37862 14.924L1.96136 12.1217ZM12.7519 5.83797L9.97892 2.90837L11.285 1.52849L14.0781 4.47931L12.7519 5.83797Z"
                fill="white"/>
        </svg>
    )
}

export default Edit;