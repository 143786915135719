import React, { useState, useEffect, useRef } from "react";
import { format, startOfMonth, endOfMonth, startOfWeek, endOfWeek, isSameMonth, isSameDay, addDays, subMonths, addMonths } from "date-fns";
import { useEffectAfterMount } from "./utils";

export type MiniCalendarProps = {
  selectionMode?: "single" | "range";
  selectedDate?: Date | null;
  selectedRange?: MiniCalendarRange;
  onChange?: (date: Date | { start: Date | null, end: Date | null }) => void;
}

export type MiniCalendarRange = {
  start: Date | null;
  end: Date | null;
}

const MiniCalendar = (options: MiniCalendarProps) => {
  const {
    selectionMode = "single",
    selectedDate: initialSelectedDate = null,
    selectedRange: initialSelectedRange = { start: null, end: null },
    onChange
  } = options;

  const [displayedDate, setDisplayedDate] = useState(initialSelectedDate || initialSelectedRange?.start || new Date());
  const [selectedDate, setSelectedDate] = useState(initialSelectedDate);
  const [selectedRange, setSelectedRange] = useState(initialSelectedRange);
  const [focusedDate, setFocusedDate] = useState(new Date());
  // Used to set focus on a day when moving with arrow keys
  const dayRefs = useRef({});

  const monthStart = startOfMonth(displayedDate);
  const monthEnd = endOfMonth(monthStart);
  const startDate = startOfWeek(monthStart);
  const endDate = endOfWeek(monthEnd);

  const dateFormat = "d";
  const rows = [];
  let days = [];
  let day = startDate;
  let formattedDate = "";

  const onDateClick = (day) => {
    if (selectionMode === "single") {
      setSelectedDate(day);
    } else if (selectionMode === "range") {
      if (!selectedRange.start || (selectedRange.start && selectedRange.end)) {
        setSelectedRange({ start: day, end: null });
      } else {
        if (day < selectedRange.start) {
          setSelectedRange({ start: day, end: selectedRange.start });
        } else {
          setSelectedRange({ ...selectedRange, end: day });
        }
      }
    }
    setFocusedDate(day);
    if (!isSameMonth(day, displayedDate)) {
      setDisplayedDate(day);
    }
  };

  const onDateKeyDown = (event, day) => {
    switch (event.key) {
      case "Enter":
        onDateClick(day);
        break;
      case "ArrowLeft": {
        const prevDay = addDays(focusedDate, -1);
        setFocusedDate(prevDay);
        if (!isSameMonth(prevDay, displayedDate)) {
          setDisplayedDate(prevDay);
        }
        break;
      }
      case "ArrowRight": {
        const nextDay = addDays(focusedDate, 1);
        setFocusedDate(nextDay);
        if (!isSameMonth(nextDay, displayedDate)) {
          setDisplayedDate(nextDay);
        }
        break;
      }
      case "ArrowUp": {
        const prevWeek = addDays(focusedDate, -7);
        setFocusedDate(prevWeek);
        if (!isSameMonth(prevWeek, displayedDate)) {
          setDisplayedDate(prevWeek);
        }
        break;
      }
      case "ArrowDown": {
        const nextWeek = addDays(focusedDate, 7);
        setFocusedDate(nextWeek);
        if (!isSameMonth(nextWeek, displayedDate)) {
          setDisplayedDate(nextWeek);
        }
        break;
      }
      default:
        break;
    }
  };

  const prevMonth = () => {
    setDisplayedDate(subMonths(displayedDate, 1));
  };

  const nextMonth = () => {
    setDisplayedDate(addMonths(displayedDate, 1));
  };

  useEffect(() => {
    if (dayRefs.current[format(focusedDate, "yyyy-MM-dd")]) {
      dayRefs.current[format(focusedDate, "yyyy-MM-dd")].focus();
    }
  }, [focusedDate]);

  useEffectAfterMount(() => {
    if (!onChange) return;

    if (selectionMode === "single") {
      onChange(selectedDate);
    } else if (selectionMode === "range" && selectedRange.start && selectedRange.end) {
      onChange(selectedRange);
    }
  }, [selectedDate, selectedRange]);

  while (day <= endDate) {
    for (let i = 0; i < 7; i++) {
      formattedDate = format(day, dateFormat);
      const cloneDay = day;

      const dayIsFocused = focusedDate && isSameDay(day, focusedDate);
      const dayIsSelected = selectedDate && isSameDay(day, selectedDate);
      const focusedDayIsThisMonth = focusedDate && isSameMonth(focusedDate, monthStart);
      const selectedDayIsThisMonth = selectedDate && isSameMonth(selectedDate, monthStart);
      const dayIsStartOfMonth = isSameDay(day, monthStart);

      const isTabStop = (dayIsSelected || !selectedDayIsThisMonth && dayIsFocused || !selectedDayIsThisMonth && !focusedDayIsThisMonth && dayIsStartOfMonth);

      const isInRange = selectedRange.start && selectedRange.end && day > selectedRange.start && day < selectedRange.end;
      const isRangeStart = selectedRange.start && isSameDay(day, selectedRange.start);
      const isRangeEnd = selectedRange.end && isSameDay(day, selectedRange.end);
      const isRangeSelected = selectedRange.start && selectedRange.end;

      days.push(
        <div
          className={`flex text-center items-center justify-center cursor-pointer  w-full ${
            isRangeStart && isRangeSelected ? "bg-gradient-to-r from-transparent from-50% to-50% to-primary-100"
              : isRangeEnd && isRangeSelected ? "bg-gradient-to-r from-primary-100 from-50% to-50% to-transparent"
              : ""
          } ${!isSameMonth(day, monthStart) && "opacity-50"}`}
          key={day.toISOString()}
          onClick={() => onDateClick(cloneDay)}
          onKeyDown={(event) => onDateKeyDown(event, cloneDay)}
          onFocus={() => setFocusedDate(cloneDay)}
          role="button"
          aria-label={`Select ${format(cloneDay, "MMMM d, yyyy")}`}
          aria-pressed={dayIsSelected || isRangeStart || isRangeEnd}
          tabIndex={isTabStop ? 0 : -1}
          // Ref used to set focus on a day when moving with arrow keys
          ref={(el) => (dayRefs.current[format(cloneDay, "yyyy-MM-dd")] = el)}
        >
          <div className={`w-full h-full flex text-center items-center justify-center ${
            !isSameMonth(day, monthStart)
            ? "text-gray-400"
            : isRangeStart || isRangeEnd || dayIsSelected
            ? "text-white"
            : "text-gray-700"
          } ${
            isRangeStart && isRangeEnd
            ? "bg-primary-500 rounded-md"
            : isRangeStart
            ? "bg-primary-500 rounded-l-md"
            : isRangeEnd
            ? "bg-primary-500 rounded-r-md"
            : dayIsSelected
            ? "bg-primary-500 rounded-md"
            : isInRange
            ? "bg-primary-50 w-full"
            : "hover:bg-primary-50 rounded-md"
          }`}>
            <span className="text-sm">{formattedDate}</span>
          </div>
        </div>
      );
      day = addDays(day, 1);
    }
    rows.push(
      <div className="h-1/6 grid grid-cols-7 justify-items-center" key={day.toISOString()} role="row">
        {days}
      </div>
    );
    days = [];
  }

  return (
    <div className="bg-white rounded-lg shadow p-4 flex flex-col h-full">
      <div className="flex items-center justify-between mb-4">
        <button type="button"
          onClick={prevMonth}
          className="text-primary-500 hover:text-primary-600 focus:outline-none text-2xl ml-4"
          aria-label="Previous Month"
        >
          &larr;
        </button>
        <div className="text-lg font-bold" id="displayed-month">
          {format(displayedDate, "MMMM yyyy")}
        </div>
        <button type="button"
          onClick={nextMonth}
          className="text-primary-500 hover:text-primary-600 focus:outline-none text-2xl mr-4"
          aria-label="Next Month"
        >
          &rarr; {/* rawrrr */}
        </button>
      </div>
      <div className="flex-1 flex flex-col h-full">
        <div role="grid" aria-labelledby="displayed-month" className="flex flex-col h-full">
          <div className="grid grid-cols-7 mb-2">
            <div className="text-xs text-gray-500 text-center" role="columnheader">Sun</div>
            <div className="text-xs text-gray-500 text-center" role="columnheader">Mon</div>
            <div className="text-xs text-gray-500 text-center" role="columnheader">Tue</div>
            <div className="text-xs text-gray-500 text-center" role="columnheader">Wed</div>
            <div className="text-xs text-gray-500 text-center" role="columnheader">Thu</div>
            <div className="text-xs text-gray-500 text-center" role="columnheader">Fri</div>
            <div className="text-xs text-gray-500 text-center" role="columnheader">Sat</div>
          </div>
          <div className="flex-1">
            {rows}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiniCalendar;
