import React, { useMemo } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon, XMarkIcon, PlusIcon } from "@heroicons/react/20/solid";
import { FormModalLink, ShowFormModalButton, EditFormModalButton, NewFormModalButton } from "@shared/modal";
import { DeleteButton } from "@shared/form_fields";
import { Table, TableEmpty, Column } from "@shared/tables/table";
import { AutoTable } from "@shared/tables/auto_table";
import { Pagination } from "@shared/tables/pagination";
import DatePicker from "@shared/date_picker";
import Flash from "@shared/flash";
import { Card } from "@shared/card";
import { ShowLink } from "@shared/html_tags";
import { useQueryParams } from "@shared/filter_sort_paginate";
import { useAjax } from "@shared/ajax";
import { useRailsContext, useEffectAfterMount } from "@shared/utils";
import { Search } from "@shared/search";

export default function HolidayListIndex({ data }) {
  const [params, setParams] = useQueryParams({
    sort_by: { default: "name" },
    sort_order: { default: "asc" },
    page: { type: "number", default: 1 },
    per_page: { type: "number", default: 50 },
    filters: { type: "object", default: [] },
    search: { type: "string", default: "" }
  });

  const {
    sort_by,
    sort_order,
    page,
    per_page,
    filters,
    search
  } = params;

  // useEffectAfterMount(() => {
  //   setParams({ page: 1 });
  // }, [filters, start, end]);

  const ajax = useAjax(data);
  const holiday_lists = ajax.data.items;

  const filteredHolidayLists = useMemo(() => {
    if (!search) return holiday_lists;
    return holiday_lists.filter(holiday_list => holiday_list.name.toLowerCase().includes(search.toLowerCase()));
  }, [ajax.data, search]);

  // const ajax = useAjax(data, { params });
  // const {
  //   items: holiday_lists,
  //   total_pages
  // } = ajax.data;

  // const setFilters = (newFilters) => setParams({ filters: newFilters });

  return (
    <>
      <Flash />

      <div className="flex justify-between items-center">
        <h1 className="font-semibold text-2xl text-slate-700">
          Holiday Lists
        </h1>

      </div>

      <Card className="mt-3 pb-6">
        <div id="holiday_lists" className="flex justify-between p-8">
          <div className="flex grow">
            <Search value={search} items={holiday_lists.map((holiday_list) => holiday_list.name)} onChange={(search) => setParams({search})} className="z-10 w-4/5 md:w-3/5" />
          </div>

          <div>
            <NewFormModalButton className="pl-3" label="New Holiday List" ajax={true} onSubmit={ajax.refresh} closeOnSubmit={true}
              props={{}}
            />
          </div>
        </div>

        <AutoTable data={filteredHolidayLists} loading={ajax.loading}
            sortBy={sort_by} sortOrder={sort_order}
            onSortChanged={(sortBy, sortOrder) => { setParams({ sort_by: sortBy, sort_order: sortOrder }) }}
            currentPage={page} pageSize={per_page}
            onPageChanged={page => setParams({ page })}
        >
          <TableEmpty>
            <p className="p-6 text-gray-900"><i>No holiday lists created yet</i></p>
          </TableEmpty>

          <Column title="Name" field="name">
            {holiday_list => <ShowLink instance={holiday_list}>{holiday_list.name}</ShowLink>}
          </Column>

          <Column sortable={false} style={{width: "1%"}}>
            {holiday_list =>
              <div className="flex gap-3 items-center">
                {/* You'll want to check for actual permissions for this */}
                <EditFormModalButton instance={holiday_list} className="w-28"
                  buttonText="Rename" title="Rename Holiday List"
                  ajax={true} onSubmit={ajax.refresh} closeOnSubmit={true}

                  props={{}}
                />
                <DeleteButton instance={holiday_list} className="w-24" ajax={true} onDelete={ajax.refresh} />
              </div>
            }
          </Column>

        </AutoTable>

      </Card>
    </>
  )
}
