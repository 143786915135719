import React, { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import { FormModalLink, ShowFormModalButton, EditFormModalButton, NewFormModalButton } from "@shared/modal";
import { DeleteButton } from "@shared/form_fields";
import { Table, TableEmpty, Column } from "@shared/tables/table";
import { AutoTable } from "@shared/tables/auto_table";
import { Pagination } from "@shared/tables/pagination";
import DatePicker from "@shared/date_picker";
import Flash from "@shared/flash";
import { Card } from "@shared/card";
import { ShowLink } from "@shared/html_tags";
import { useQueryParams } from "@shared/filter_sort_paginate";
import { useAjax } from "@shared/ajax";
import { useRailsContext, useEffectAfterMount } from "@shared/utils";

export default function CustomFunctionIndex({ data }) {
  const railsContext = useRailsContext();

  const [params, setParams] = useQueryParams({
    sort_by: { default: "name" },
    sort_order: { default: "asc" },
    page: { type: "number", default: 1 },
    per_page: { type: "number", default: 50 },
    filters: { type: "object", default: [] }
  });

  const {
    sort_by,
    sort_order,
    page,
    per_page,
    filters
  } = params;

  // useEffectAfterMount(() => {
  //   setParams({ page: 1 });
  // }, [filters, start, end]);

  const ajax = useAjax(data);
  const custom_functions = ajax.data.items;

  // Keep polling for deploy status if still pending
  useEffect(() => {
    for (let custom_function of custom_functions) {
      if (custom_function.deploy_status && custom_function.deploy_status !== "success"
          && custom_function.deploy_status !== "failed"
          && custom_function.deploy_status !== "timed_out") {
        setTimeout(() => ajax.refresh(), 1500);
        return
      }
    }
  }, [custom_functions]);

  // const setFilters = (newFilters) => setParams({ filters: newFilters });

  return (
    <>
      {/* <Flash /> */}

      <div className="flex justify-between items-center">
        <h1 className="font-semibold text-2xl text-slate-700">
          Custom functions
        </h1>

      </div>

      <Card className="mt-3 pb-6">
        <div id="custom_functions" className="flex justify-between p-8">
          <div className="flex flex-col gap-5">
          {/* <div className="flex gap-3">
              <DatePicker label="Created at" dateRange={{start, end}} onChange={({start, end}) => setParams({ start, end })} />
            </div>

            {filters.length > 0 &&
              <div className="flex flex-col gap-2">
                {filters.map((filter, index) => (
                  <div key={index} className="flex gap-2 items-center">
                    <label className="inline-block font-bold min-w-12" htmlFor={filter.field}>{filter.field}</label>
                    <select value={filter.comparison}
                      className="bg-transparent focus:outline-none rounded-md border border-gray-300 outline-none px-3 py-1 pr-9 disabled:bg-gray-50 disabled:opacity-60"
                        onChange={(e) => setFilters(filters.map((f, i) => i === index ? { ...f, comparison: e.target.value } : f))} >
                      <option value="eq">is</option>
                      <option value="not_eq">is not</option>
                      <option value="lt">&lt;</option>
                      <option value="lte">&lt;=</option>
                      <option value="gt">&gt;</option>
                      <option value="gte">&gt;=</option>
                      <option value="contains">contains</option>
                      <option value="not_contains">doesn't contain</option>
                      <option value="start">starts with</option>
                      <option value="end">ends with</option>
                    </select>

                    <input id={filter.field} type="text" value={filter.value} onChange={(e) => setFilters(filters.map((f, i) => i === index ? { ...f, value: e.target.value } : f))}
                      className="rounded-md border border-gray-300 outline-none px-3 py-1 disabled:bg-gray-50 disabled:opacity-60" />

                    <button onClick={() => setFilters(filters.filter((f, i) => i !== index))}>
                      <XMarkIcon className="h-7 w-7 text-gray-500 hover:text-gray-700" />
                    </button>
                  </div>
                ))}
              </div>
            } */}

            {/* <div className="inline-flex gap-2">
              <Popover className="relative inline-block">
                {({ open }) => (
                <>
                  <Popover.Button
                    className="inline-flex items-center gap-1 rounded-full border border-gray-300 outline-none px-3 py-1 disabled:bg-gray-50 disabled:opacity-60">
                    <PlusIcon className="h-5 w-5 inline-block" />
                    Add filter
                    <ChevronDownIcon className="h-4 w-5 inline-block" />
                  </Popover.Button>
                  <Transition as={Fragment} enter="transition ease-out duration-75" enterFrom="opacity-0 translate-y-[-5px]"
                    enterTo="opacity-100 translate-y-0" leave="transition ease-in duration-75" leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-[-5px]">
                    <Popover.Panel static
                      className="absolute z-10 mt-2 w-64 origin-top-left left-0 bg-white border border-gray-200  rounded-lg shadow-lg outline-none">
                      {filter_columns?.map((column, index) => (
                      <Popover.Button key={index}
                        className="block w-full text-left px-4 py-2 text-sm text-gray-800 hover:bg-blue-50 hover:text-blue-700"
                        onClick={()=> setFilters([...filters, { field: column, comparison: "contains", value: "" }])}>
                        {column}
                      </Popover.Button>
                      ))}
                      {!filter_columns || filter_columns?.length === 0 &&
                        <p className="p-4 text-gray-900"><i>No filterable columns defined. Check your model code for a `self.filter_columns` method.</i></p>
                      }
                    </Popover.Panel>
                  </Transition>
                </>
                )}
              </Popover>
            </div> */}
          </div>

          <div>
            <NewFormModalButton className="pl-3" label="New Custom function" size="7xl" ajax={true} onSubmit={ajax.refresh} closeOnSubmit={true} />
          </div>
        </div>

        {/* <Table */}
        <AutoTable data={custom_functions} loading={ajax.loading}
            sortBy={sort_by} sortOrder={sort_order}
            onSortChanged={(sortBy, sortOrder) => { setParams({ sort_by: sortBy, sort_order: sortOrder }) }}
            currentPage={page} pageSize={per_page}
            onPageChanged={page => setParams({ page })}
        >
          <TableEmpty>
            <p className="p-6 text-gray-900"><i>No custom functions created yet</i></p>
          </TableEmpty>

          <Column title="Name" field="name" blankValue="-" />
          <Column title="ID" field="deno_project_id" blankValue="-" />
          <Column title="Deploy Status" field="deploy_status" blankValue={<i>never_deployed</i>} />
          <Column title="URL">
            {custom_function =>
              custom_function.deploy_status === "success" ?
                <a href={`/api/function/${custom_function.name}?token=XX`} target="_blank" className="text-blue-600 underline">
                  {`/api/function/${custom_function.name}?token=XX`}
                  <ArrowTopRightOnSquareIcon className="h-4 w-4 inline-block ml-1 underline" />
                </a>
              : "-"
            }
          </Column>
          <Column title="Direct URL" field="latest_url">
            {custom_function => custom_function.latest_url ?
                <a href={custom_function.latest_url + "?token=XX"} target="_blank" className="text-blue-600 underline">
                  {custom_function.latest_url + "?token=XX"}
                  <ArrowTopRightOnSquareIcon className="h-4 w-4 inline-block ml-1 underline" />
                </a>
              : "-"
            }
          </Column>
          {/* <Column title="Code" field="code" blankValue="-" /> */}
          <Column sortable={false} style={{width: "1%"}}>
            {custom_function =>
              <div className="flex gap-3 items-center">
                {/* You'll want to check for actual permissions for this */}
                <ShowFormModalButton instance={custom_function} className="w-24" size="7xl" />
                <EditFormModalButton instance={custom_function} className="w-24" size="7xl"
                  ajax={true} onSubmit={ajax.refresh} closeOnSubmit={true}  />
                <DeleteButton instance={custom_function} className="w-24" ajax={true} onDelete={ajax.refresh} />
              </div>
            }
          </Column>

        </AutoTable>
        {/* </Table> */}

        {/* <div className="flex items-center justify-between bg-white mt-4 pr-8 sm:rounded-b-lg">
          <Pagination currentPage={page} totalPages={total_pages} onPageChange={(page) => setParams({ page: page })} />
        </div> */}
      </Card>
    </>
  )
}
