import React, { useEffect, useState, useRef } from "react";

import Dropdown from "./inputs/Dropdown";
import useOrganizationStore from "./store/organizationStore";
import OrganizationModal from "./schedule/OrganizationModal";
import MenuDrawer from "./Menu/MenuDrawer";
import { DefaultApplicationName } from "./constants/AppplicationNames";
import { useEffectAfterMount, useRailsContext } from "@shared/utils";
import { debug } from "webpack";

const Menu = (props) => {
  const railsContext = useRailsContext();
  const organizations = useOrganizationStore((state) => state.organizations);
  const { setApplicationName } = useOrganizationStore();
  const [organizationId, setOrganizationId] = useState(
    railsContext?.current_organization?.id
  );
  const [openOrganizationModal, setOpenOrganizationModal] = useState(false);

  const getOrganizations = useOrganizationStore(
    (state) => state.getOrganizations
  );

  useEffect(() => {
    getOrganizations();
  }, []);

  useEffect(() => {
    const currentOrganization = railsContext?.current_organization;

    if (currentOrganization && organizations?.length > 0) {
      const currentOrganizationName = currentOrganization.application_name;
      setApplicationName(
        currentOrganizationName
          ? currentOrganizationName
          : DefaultApplicationName().name
      ).then(() => {});
    } else {
      setApplicationName(DefaultApplicationName().name);
    }
  }, [organizations]);

  const [open, setOpen] = React.useState(false);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const selectOrgForm = useRef(null)

  useEffectAfterMount(() => {
    selectOrgForm.current.submit()
  }, [organizationId]);

  return (
    <div>
      <div
        className={`absolute flex w-[100%] top-4 ${open ? "pl-64" : "pl-20"}`}
      >
        <a href="#" onClick={() => setOpen(!open)}>
          <div className="p-2">
            <img
              className="max-w-[50px] m-auto"
              src={require("../../assets/images/menu-icon.svg")}
            />
          </div>
        </a>
        <form id="select-org-form" ref={selectOrgForm} action="/select_organization" method="post">
          {organizations?.length > 0 && (
            <Dropdown
              name="organization_id"
              labelTop={true}
              sx={{ height: "38px", background: "white" }}
              label="Organizations"
              className={`!w-[300px] !absolute right-[70px] top-[-3px] drop-down-position`}
              selectedValue={organizationId}
              onChange={value => setOrganizationId(value)}
              data={organizations}
            ></Dropdown>
          )}
        </form>
      </div>
      <MenuDrawer
        props={props}
        open={open}
        handleDrawerClose={() => handleDrawerClose()}
      />

      <OrganizationModal
        organizationName={
          organizations?.find((o) => o.id === organizationId)?.name
        }
        organizationId={organizationId}
        open={openOrganizationModal}
        onClose={() => setOpenOrganizationModal(false)}
      />
    </div>
  );
};

export default Menu;
