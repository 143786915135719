import React from "react"
const Delete = () => {

    return (
        <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.4324 2.45574H10.2484V1.15203C10.2484 0.84649 10.1288 0.553468 9.91591 0.337421C9.70303 0.121374 9.4143 0 9.11324 0H4.88676C4.5857 0 4.29697 0.121374 4.0841 0.337421C3.87122 0.553468 3.75162 0.84649 3.75162 1.15203V2.45574H0.567568C0.417039 2.45574 0.272676 2.51642 0.166237 2.62445C0.0597971 2.73247 0 2.87898 0 3.03175V5.56621C0 5.71898 0.0597971 5.86549 0.166237 5.97351C0.272676 6.08154 0.417039 6.14222 0.567568 6.14222H1.34892V14.4925C1.34892 15.0017 1.54824 15.4901 1.90304 15.8502C2.25784 16.2103 2.73905 16.4125 3.24081 16.4125H10.7611C11.2628 16.4125 11.7441 16.2103 12.0989 15.8502C12.4536 15.4901 12.653 15.0017 12.653 14.4925V6.14222H13.4324C13.583 6.14222 13.7273 6.08154 13.8338 5.97351C13.9402 5.86549 14 5.71898 14 5.56621V3.03175C14 2.87898 13.9402 2.73247 13.8338 2.62445C13.7273 2.51642 13.583 2.45574 13.4324 2.45574ZM4.88676 1.15203H9.11324V2.45574H4.88676V1.15203ZM11.5084 14.4925C11.5084 14.6962 11.4286 14.8915 11.2867 15.0356C11.1448 15.1796 10.9523 15.2605 10.7516 15.2605H3.24081C3.04011 15.2605 2.84762 15.1796 2.7057 15.0356C2.56378 14.8915 2.48405 14.6962 2.48405 14.4925V6.14222H11.5178L11.5084 14.4925ZM12.8649 4.9902H1.13514V3.60776H12.8649V4.9902Z" fill="#2F6E8C"/>
            <path d="M7.00006 13.6731C7.15059 13.6731 7.29495 13.6125 7.40139 13.5044C7.50783 13.3964 7.56763 13.2499 7.56763 13.0971V8.12997C7.56763 7.9772 7.50783 7.83069 7.40139 7.72267C7.29495 7.61464 7.15059 7.55396 7.00006 7.55396C6.84953 7.55396 6.70517 7.61464 6.59873 7.72267C6.49229 7.83069 6.4325 7.9772 6.4325 8.12997V13.0971C6.4325 13.2499 6.49229 13.3964 6.59873 13.5044C6.70517 13.6125 6.84953 13.6731 7.00006 13.6731Z" fill="#2F6E8C"/>
            <path d="M9.081 13.6731C9.23152 13.6731 9.37589 13.6125 9.48233 13.5044C9.58877 13.3964 9.64856 13.2499 9.64856 13.0971V8.12997C9.64856 7.9772 9.58877 7.83069 9.48233 7.72267C9.37589 7.61464 9.23152 7.55396 9.081 7.55396C8.93047 7.55396 8.7861 7.61464 8.67966 7.72267C8.57323 7.83069 8.51343 7.9772 8.51343 8.12997V13.0971C8.51343 13.2499 8.57323 13.3964 8.67966 13.5044C8.7861 13.6125 8.93047 13.6731 9.081 13.6731Z" fill="#2F6E8C"/>
            <path d="M4.91889 13.6731C5.06941 13.6731 5.21378 13.6125 5.32022 13.5044C5.42666 13.3964 5.48645 13.2499 5.48645 13.0971V8.12997C5.48645 7.9772 5.42666 7.83069 5.32022 7.72267C5.21378 7.61464 5.06941 7.55396 4.91889 7.55396C4.76836 7.55396 4.62399 7.61464 4.51756 7.72267C4.41112 7.83069 4.35132 7.9772 4.35132 8.12997V13.0971C4.35132 13.2499 4.41112 13.3964 4.51756 13.5044C4.62399 13.6125 4.76836 13.6731 4.91889 13.6731Z" fill="#2F6E8C"/>
        </svg>

    )
}

export default Delete;