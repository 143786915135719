import React, { useState } from "react";
import { useForm } from "react-hook-form";
import FormLabel from "../components/form-label";
import LoginFlowLinks from "../components/login-flow-links";
import { Button } from "@shared/form_fields";
import LoginFlowCard from "../components/login-flow-card";

const UnlockForm = ({ authenticity_token, onSuccess }) => {
  const [error, setError] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = handleSubmit(async (data) => {
    $.ajax({
      method: "POST",
      url: "/users/unlock",
      contentType: "application/x-www-form-urlencoded",
      data: {
        utf8: "✓",
        "user[email]": data.email,
        authenticity_token: authenticity_token,
        commit: "Resend unlock instructions",
      },
      success: function () {
        onSuccess();
      },

      error: function (err) {
        console.log("error: ", err.responseText);
        setError(err.responseText);
      },
    });
  });

  return (
    <LoginFlowCard error={error}>
      <div className="text-4xl font-medium text-gray-500">
        Resend unlock instructions
      </div>
      <form onSubmit={onSubmit} className="flex flex-col gap-4">
        <div className="flex flex-col gap-0">
          <FormLabel label="Email" />
          <input
            type="email"
            name="email"
            className="form-control rounded-md"
            placeholder="Email"
            {...register("email")}
          />
        </div>

        <div>
          <Button type="submit">Resend unlock instructions</Button>
        </div>
        <LoginFlowLinks currentView="unlock" />
      </form>
    </LoginFlowCard>
  );
};
export default UnlockForm;
