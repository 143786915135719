import React, { useMemo } from "react";
import { Table, Column } from "@shared/tables/table";
import { Pagination } from "@shared/tables/pagination";
import { useFilteredSortedPaginatedArray } from "@shared/filter_sort_paginate";
import { getNestedValueFromString } from "@shared/utils";

export const AutoTable = (props) => {
  const sortBy = props.sortBy || "id";
  const sortOrder = props.sortOrder || "asc";
  const currentPage = props.currentPage || 1;
  const pageSize = props.pageSize || 50;
  const onPageChanged = props.onPageChanged;
  const onSortChanged = props.onSortChanged;

  const pageCount = Math.ceil((props.data || []).length / pageSize);

  // For columns that render their values via a function, we want to use that same function to sort the array
  const sortFunctions = useSortFunctions(props.children);

  const visibleArray = useFilteredSortedPaginatedArray({
    array: props.data || [],
    sort: { by: sortBy, order: sortOrder },
    paging: { current: currentPage, perPage: pageSize },
    sortFunctions
  });

  const handlePageChanged = (page) => {
    // setPaging({ current: page, perPage: paging.perPage });
    onPageChanged && onPageChanged(page);
  }

  const handleSortChanged = ({by, order}) => {
    // setSort({ by, order });
    onSortChanged && onSortChanged(by, order);
  }

  return <>
    <Table {...props} sortBy={sortBy} sortOrder={sortOrder} data={visibleArray} onSort={handleSortChanged}>
      {props.children}
    </Table>
    <div className="flex items-center justify-between bg-white mt-4 pr-8 sm:rounded-b-lg">
      <Pagination currentPage={currentPage} totalPages={pageCount} onPageChange={handlePageChanged} />
    </div>
  </>
}

/**
 * This hook will return an object with sort functions for each column that has a sortFn prop or
 * a function as its children
 */
const useSortFunctions = (children) => {
  return useMemo(() => {
    let sortFunctions = {};
    React.Children.toArray(children).filter(child => child.type === Column).forEach(column => {
      // If a sortFn prop is provided, use that to sort the column
      if (column.props.sortFn) {
        sortFunctions[column.props.field] = column.props.sortFn;

      // If a function is provided as a child, use that to sort the column,
      // unless the function returns a React component, in which case we sort by the field name like normal
      } else if (!column.props.sortField && column.props.children && typeof column.props.children === 'function') {
        sortFunctions[column.props.field] = (row) => {
          let sortValue = column.props.children(row);
          if (sortValue === null || sortValue === undefined) return "";
          // If the type of sortValue is not a string, number, or date, sort by the field name
          if (typeof sortValue !== "string" && typeof sortValue !== "number" && !(sortValue instanceof Date)) {
            sortValue = getNestedValueFromString(row, column.props.field);
          }

          return sortValue;
        }
      }
    });

    return sortFunctions;
  }, [children]);
}
