import React from "react";
import Flash from "@shared/flash";
import { Card } from "@shared/card";
import { Tabs, Tab } from "@shared/tabs";
import { IndexLink } from "@shared/html_tags";
import { useRailsContext } from "@shared/utils";

export default function CustomFunctionShow({custom_function}) {

  return (
    <>
      <Flash />

      <div className="flex justify-between items-center">
        <h1 className="font-semibold text-2xl text-slate-700">
          Custom function: {custom_function.id}
        </h1>
      </div>

      <IndexLink to="custom_functions" className="block mt-1 mb-4 font-normal text-sm">&larr; Back to Custom functions</IndexLink>

      <Card className="mt-3">
        <Tabs className="">
          <Tab title="Details">
            <div className="p-6">
              <dl className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                  <div>
                    <dt className="font-semibold">Name</dt>
                    <dd>{custom_function.name}</dd>
                  </div>
                                  <div>
                    <dt className="font-semibold">Deno project</dt>
                    <dd>{custom_function.deno_project_id}</dd>
                  </div>
                                  <div>
                    <dt className="font-semibold">Code</dt>
                    <dd>{custom_function.code}</dd>
                  </div>
                              </dl>
            </div>
          </Tab>
        </Tabs>
      </Card>
    </>
  )
}
