import React from "react";
import { CommonProps } from "../props/CommonProps";
import MuiModal from "@mui/material/Modal";
import { Box } from "@mui/material";
import Button from "../inputs/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface ModalProps extends CommonProps {
  open?: boolean;
  onClose?: any;
  onSubmit?: any;
  title?: string;
  content?: string;
  cancelText?: string;
  okText?: string;
  isLoadingAction?: boolean;
  isConfirmationModal?: boolean;
  disabled?: boolean;
  minHeight?: string;
  isOnlyOneButton?: boolean;
  width?: number;
  height?: number;
  full?: boolean;
}

export const Modal = ({
  children,
  open,
  onClose,
  onSubmit,
  title,
  content,
  disabled,
  cancelText = "Cancel",
  isLoadingAction = false,
  okText = "Save",
  isConfirmationModal = false,
  isOnlyOneButton = false,
  minHeight,
  width = 40,
  height = 35,
  full = false,
}: ModalProps) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    p: 4,
  };

  const handleSubmit = () => {
    onSubmit();
  };

  return (
    <MuiModal
      open={open}
      onClose={() => {
        onClose();
      }}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={[
          style,
          { minHeight: minHeight, overflowY: !isConfirmationModal && "auto", maxHeight: !isConfirmationModal ? "52rem" : "10rem" },
        ]}
        className={`${
          isConfirmationModal
            ? ""
            : `w-[96%] md:w-[${width}rem] h-[${height}rem]`
        } shadow-md !border-none !rounded-lg`}
      >
        {isConfirmationModal && (
          <CheckCircleIcon
            sx={{
              position: "absolute",
              color: "#2f6c8b",
              fontSize: "100px",
              left: "50%",
              top: "-25px",
              marginLeft: "-50px",
            }}
          />
        )}
        <div
          className={`bg-white ${full ? "" : "pt-5 pb-6"} ${
            isConfirmationModal && "!pb-0"
          }`}
        >
          <p className="text-[#2f6c8b] text-2xl font-bold">{title}</p>
          <p className="mt-2">{content}</p>
          {children}
        </div>

        <div
          className={`flex flex-row-reverse gap-x-2 mt-5 right-8 bottom-8 ${
            isConfirmationModal && "justify-center"
          }`}
        >
          {!isConfirmationModal && (
            <>
              {!isOnlyOneButton && (
                <Button
                  variant="contained"
                  className="!bg-[#2f6c8b] !capitalize !h-8 !w-28"
                  disabled={disabled}
                  cirularProgress={isLoadingAction}
                  onClick={() => handleSubmit()}
                >
                  {okText}
                </Button>
              )}

              <Button
                variant="outlined"
                className={`!border-[#2f6c8b] !text-[#2f6c8b] !capitalize !border-2 !h-8 !w-28 ${
                  isConfirmationModal &&
                  "!bg-[#2f6c8b] !text-[#FFF] !w-[253px] !h-[40px] m-auto"
                }`}
                onClick={() => onClose()}
              >
                {cancelText}
              </Button>
            </>
          )}
        </div>
      </Box>
    </MuiModal>
  );
};

export default Modal;
