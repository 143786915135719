import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import Modal from "../layout/Modal";
import ResultModal from "../layout/ResultModal";
import Button from "../inputs/Button";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Dropdown from "../inputs/Dropdown";
import { updateOrganization } from "../services/organizationsService";

declare namespace Intl {
  type Key =
    | "calendar"
    | "collation"
    | "currency"
    | "numberingSystem"
    | "timeZone"
    | "unit";

  function supportedValuesOf(input: Key): string[];
}

const OrganizationModal = ({
  open,
  onClose,
  organizationId,
  organizationName,
}) => {
  const [orgName, setOrgName] = useState<string>("");

  const [resultTitle, setResultTitle] = useState("");
  const [resultContent, setResultContent] = useState("");
  const [isActionCompleted, setIsActionCompleted] = useState(false);
  const [timeZone, setTimeZone] = useState(0);
  const [isDirtyForm, setIsDirtyForm] = useState(false);
  const [openOrgNameModal, setOpenOrgNameModal] = useState(false);

  const handleEdit = () => {};

  useEffect(() => {
    if (organizationName) {
      setOrgName(organizationName);
    }
  }, [organizationName]);

  const updateOrganizationName = () => {
    updateOrganization({ id: organizationId, name: orgName }).then(
      (response) => {}
    );
  };

  return (
    <Modal
      open={open}
      cancelText="Close"
      onClose={() => {
        if (isDirtyForm) {
          window.location.reload();
          return;
        }
        onClose();
      }}
      isOnlyOneButton
      onSubmit={() => handleEdit()}
      title="Settings"
      width={40}
      children={
        <>
          <p className="mt-7">Organization Name</p>
          <div className="flex items-center">
            <TextField
              id="outlined-basic"
              label=""
              className="!w-[300px] !mr-10"
              fullWidth
              size="small"
              value={orgName}
              onChange={(e) => {
                setIsDirtyForm(true);
                setOrgName(e.target.value);
              }}
            />
            <div className="flex justify-end">
              <Button
                variant="contained"
                onClick={(e) => {
                  setOpenOrgNameModal(!openOrgNameModal);
                }}
                className="!bg-[#2f6c8b] !h-8 !w-28 !capitalize"
              >
                Update
              </Button>
            </div>
          </div>
          <p className="mt-7">API Key</p>
          <div className="flex items-center">
            <TextField className="!mr-1 !w-[300px]" label="" size="small" />
            <ContentCopyIcon className="!mr-5 text-[#2e6c8b] !text-lg cursor-pointer" />
            <Button
              variant="contained"
              className="!bg-[#2f6c8b] !h-8 !w-40 !capitalize"
            >
              Generate API Key
            </Button>
          </div>
          <p className="mt-8">Selected timezone:</p>
          <Dropdown
            selectedValue={timeZone}
            label="name"
            value="id"
            data={Intl.supportedValuesOf("timeZone").map((x, i) => {
              return { name: x, id: i };
            })}
            onChange={(e) => setTimeZone(e.target.value)}
          />

          <ResultModal
            title={resultTitle}
            content={resultContent}
            open={isActionCompleted}
            onClose={() => {
              setIsActionCompleted(false);
            }}
          />
        </>
      }
    />
  );
};

export default OrganizationModal;
