
import React, { useMemo } from "react";
import { organizationUrlFor, useRailsContext } from "@shared/utils";
import { useObjectState } from "@shared/hooks";
import { EditFormModalButton, NewFormModalButton } from "@shared/modal";
import { DeleteButton } from "@shared/form_fields";
import { AutoTable } from "@shared/tables/auto_table";
import { Column, TableEmpty } from "@shared/tables/table";
import { format, toZonedTime } from "date-fns-tz";

const SpecialSchedulesTab = ({ ajax }) => {
  const [params, setParams] = useObjectState({
    sortBy: "date",
    sortOrder: "asc",
    page: 1,
    perPage: 50,
    search: ""
  });
  const { sortBy, sortOrder, page, perPage, search } = params;
  const { data: schedule } = ajax;
  const { special_schedules } = schedule;

  const filteredSpecialSchedules = useMemo(() => {
    if (!search) return special_schedules;
    return special_schedules.filter(special_schedule => special_schedule.name.toLowerCase().includes(search.toLowerCase()));
  }, [ajax.data, search]);

  return <>
    <div id="special_schedules" className="flex justify-between px-8 py-6">
      <div className="flex grow">
        {/* <Search value={search} items={special_schedules.map((prompt) => prompt.name)} onChange={(search) => setParams({ search })} className="z-10 w-4/5 md:w-3/5" /> */}
      </div>

      <div>
        <NewFormModalButton size="xl" className="pl-3" label="New Special Schedule" ajax={true} onSubmit={ajax.refresh} closeOnSubmit={true}
          model="special_schedule" template="special_schedules/form" formAction={organizationUrlFor("special_schedules")}
          props={{ schedule_id: schedule.id }}
        />
      </div>
    </div>

    <AutoTable data={filteredSpecialSchedules} loading={ajax.loading}
      sortBy={sortBy} sortOrder={sortOrder}
      onSortChanged={(sortBy, sortOrder) => { setParams({ sortBy, sortOrder }) }}
      currentPage={page} pageSize={perPage}
      onPageChanged={page => setParams({ page })}
    >
      <TableEmpty>
        <p className="p-6 text-gray-900"><i>No special schedules found.</i></p>
      </TableEmpty>

      <Column title="Date" field="date" sortField="date">
        {special_schedule => format(toZonedTime(new Date(special_schedule.date), "UTC"), "MM/dd/yyyy")}
      </Column>
      <Column title="Start Time" field="start_time" sortField="start_time">
        {special_schedule => {
          return special_schedule.closed_all_day ? "-" :
            format(toZonedTime(new Date(special_schedule.start_time), "UTC"), "h:mm a")
        }}
      </Column>
      <Column title="End Time" field="end_time" sortField="end_time">
      {special_schedule => {
          return special_schedule.closed_all_day ? "-" :
            format(toZonedTime(new Date(special_schedule.end_time), "UTC"), "h:mm a")
        }}
      </Column>
      <Column title="Closed All Day" field="closed_all_day">
        {special_schedule => special_schedule.closed_all_day ? "Yes" : "No"}
      </Column>

      <Column sortable={false} style={{ width: "1%" }}>
        {special_schedule =>
          <div className="flex gap-3 items-center">
            {/* You'll want to check for actual permissions for this */}
            <EditFormModalButton size="xl" instance={special_schedule} className="w-24"
              ajax={true} onSubmit={ajax.refresh} closeOnSubmit={true}
              props={{ schedule_id: schedule.id }}
            />
            <DeleteButton instance={special_schedule} className="w-24" ajax={true} onDelete={ajax.refresh}
              instanceName={format(toZonedTime(new Date(special_schedule.date), "UTC"), "MM/dd/yyyy")}
            />
          </div>
        }
      </Column>

    </AutoTable>
  </>
};

export default SpecialSchedulesTab;
