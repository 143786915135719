import React from "react";
import { FormField, CheckboxField, SelectField } from "@shared/form_fields";

export default function EntityForm({time_zones, groups, schedules, holiday_lists}) {
  return (
    <>
      <FormField field="name" />
      <FormField field="code" />
      <SelectField field="group_id" options={groups} />
      <SelectField field="schedule_id" options={schedules} />
      <SelectField field="holiday_list_id" options={holiday_lists} />
      <SelectField field="time_zone" label="Time Zone" options={time_zones} defaultLabel="- Default -" />
    </>
  )
}
