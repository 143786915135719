import React, { useMemo } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon, XMarkIcon, PlusIcon } from "@heroicons/react/20/solid";
import { FormModalLink, ShowFormModalButton, EditFormModalButton, NewFormModalButton } from "@shared/modal";
import { DeleteButton } from "@shared/form_fields";
import { Table, TableEmpty, Column } from "@shared/tables/table";
import { AutoTable } from "@shared/tables/auto_table";
import { Pagination } from "@shared/tables/pagination";
import DatePicker from "@shared/date_picker";
import Flash from "@shared/flash";
import { Card } from "@shared/card";
import { ShowLink } from "@shared/html_tags";
import { useQueryParams } from "@shared/filter_sort_paginate";
import { useAjax } from "@shared/ajax";
import { useRailsContext, useEffectAfterMount } from "@shared/utils";
import { Search } from "@shared/search";

export default function ScheduleIndex({ data }) {
  const [params, setParams] = useQueryParams({
    sort_by: { default: "name" },
    sort_order: { default: "asc" },
    page: { type: "number", default: 1 },
    per_page: { type: "number", default: 50 },
    search: { type: "string", default: "" }
  });

  const {
    sort_by,
    sort_order,
    page,
    per_page,
    search
  } = params;

  const ajax = useAjax(data);
  const schedules = ajax.data.items;

  const filteredSchedules = useMemo(() => {
    if (!search) return schedules;
    return schedules.filter(schedule => schedule.name.toLowerCase().includes(search.toLowerCase()));
  }, [ajax.data, search]);

  return (
    <>
      <Flash />

      <div className="flex justify-between items-center">
        <h1 className="font-semibold text-2xl text-slate-700">
          Schedules
        </h1>

      </div>

      <Card className="mt-3 pb-6">
        <div id="schedules" className="flex justify-between p-8">
          <div className="flex grow">
            <Search value={search} items={schedules.map((schedule) => schedule.name)} onChange={(search) => setParams({search})} className="z-10 w-4/5 md:w-3/5" />
          </div>

          <div>
            <NewFormModalButton className="pl-3" label="New Schedule" ajax={true} onSubmit={ajax.refresh} closeOnSubmit={true}
              props={{}}
            />
          </div>
        </div>

        {/* <Table */}
        <AutoTable data={filteredSchedules} loading={ajax.loading}
            sortBy={sort_by} sortOrder={sort_order}
            onSortChanged={(sortBy, sortOrder) => { setParams({ sort_by: sortBy, sort_order: sortOrder }) }}
            currentPage={page} pageSize={per_page}
            onPageChanged={page => setParams({ page })}
        >
          <TableEmpty>
            <p className="p-6 text-gray-900"><i>No schedules created yet</i></p>
          </TableEmpty>

          <Column title="Name" field="name">
            {schedule => <ShowLink instance={schedule}>{schedule.name}</ShowLink>}
          </Column>
          <Column sortable={false} style={{width: "1%"}}>
            {schedule =>
              <div className="flex gap-3 items-center">
                {/* You'll want to check for actual permissions for this */}
                <EditFormModalButton instance={schedule} className="w-28"
                  buttonText="Rename" title="Rename Schedule"
                  ajax={true} onSubmit={ajax.refresh} closeOnSubmit={true}
                  props={{}}
                />
                <DeleteButton instance={schedule} className="w-24" ajax={true} onDelete={ajax.refresh} />
              </div>
            }
          </Column>

        </AutoTable>
        {/* </Table> */}

        {/* <div className="flex items-center justify-between bg-white mt-4 pr-8 sm:rounded-b-lg">
          <Pagination currentPage={page} totalPages={total_pages} onPageChange={(page) => setParams({ page: page })} className="sm:rounded-b-lg" />
        </div> */}
      </Card>
    </>
  )
}
