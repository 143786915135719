import React, { useState } from "react";
import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import Modal from "../layout/Modal";
import axios from "axios";
import ResultModal from "../layout/ResultModal";
import { deleteUser } from "../services/userService";
import { useRailsContext } from "@shared/utils";

axios.defaults.headers.common["Accept"] = "application/json";

const DeleteUserModal = ({ open, onClose, user }) => {
  const { current_organization } = useRailsContext();

  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [isActionCompleted, setIsActionCompleted] = useState(false);
  const [resultTitle, setResultTitle] = useState("");
  const [resultContent, setResultContent] = useState("");

  const handleDelete = () => {
    deleteUser(user.id).then((response) => {
        window.location.href = `/${current_organization.id}/users`;
    }).catch((error) => {
      console.error("Error deleting resource:", error);
      setIsLoadingAction(false);
      onClose();
      setResultTitle("Error!");
      setResultContent("Could not delete user.");
      setIsActionCompleted(true);
      setTimeout(() => {
        setIsActionCompleted(false);
        window.location.href = `/${current_organization.id}/users`;
      }, 2000);
    });
  };

  return (
    <>
      <Modal
        okText="Delete"
        content=""
        onClose={onClose}
        onSubmit={() => handleDelete()}
        open={open}
        isLoadingAction={isLoadingAction}
        title="Delete User"
        height={20}
      >
        <p>
          The user <strong>{`${user.first_name} ${user.last_name}`}</strong> will be deleted.
        </p>
        <br />
        <p>Are you sure?</p>
      </Modal>
      <ResultModal
        title={resultTitle}
        content={resultContent}
        open={isActionCompleted}
        onClose={() => {
          setIsActionCompleted(false);
        }}
      />
    </>
  );
};

export default DeleteUserModal;
