import { Popover, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { OutlineButton } from "./form_fields";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import{ CalendarDaysIcon } from "@heroicons/react/24/outline";
import MiniCalendar, { MiniCalendarRange } from "./mini_calendar";

const DatePicker = ({ dateRange, onChange, label = null, align = "left" }) => {
  const startDate = dateRange.start ? dateRange.start.toLocaleDateString() : '';
  const endDate = dateRange.end ? dateRange.end.toLocaleDateString() : '';
  const [tempDateRange, setTempDateRange] = useState<MiniCalendarRange>(dateRange);

  const alignClasses = align === "right" ? "right-0" : "left-0";

  return <Popover className="relative">
    <>

      <Popover.Button>
        <OutlineButton className="min-w-60 pr-1 flex gap-2 justify-between items-center text-gray-700 border-gray-700">
          <CalendarDaysIcon className="text-gray-700 inline-block h-5 w-5 mb-[1px] transition duration-150 ease-in-out"
            aria-hidden="true"
          />
          {label &&
            <span className="font-semibold">{label}:</span>
          }
          {startDate ?
            <span>{startDate} - {endDate}</span>
            : <span>Select Date</span>
          }
          <ChevronDownIcon className="text-gray-700 inline-block h-5 w-5 mb-[1px] transition duration-150 ease-in-out"
            aria-hidden="true"
          />
        </OutlineButton>
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="opacity-0 translate-y-[-5px]"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-75"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-[-5px]"
      >
        <Popover.Panel className={`absolute ${alignClasses} z-10 mt-3 w-80 h-80`}>
          {({ close }) => (
            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5 h-full">
              <MiniCalendar selectionMode="range" selectedRange={tempDateRange} onChange={(range: MiniCalendarRange) => {
                setTempDateRange(range);
                if (range.start && range.end) {
                  onChange && onChange(range);
                  close();
                }
              }} />
            </div>
          )}
        </Popover.Panel>
      </Transition>
    </>
  </Popover>
}

export default DatePicker;
