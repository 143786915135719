import React, { useState } from "react";
import { useForm } from "react-hook-form";
import LoginFormWrapper from "../components/login-flow-wrapper";
import FormLabel from "../components/form-label";
import LoginFlowLinks from "../components/login-flow-links";
import LoginFlowCard from "../components/login-flow-card";
import { Button, FormField, HiddenField } from "@shared/form_fields";
import { Form } from "@shared/form_component";
import Flash from "@shared/flash";

const ForgotPasswordEdit = ({ user, reset_password_token }) => {

  return (
    <LoginFormWrapper>
      <LoginFlowCard>
        <div className="text-4xl font-medium text-gray-500">
          Change your password
        </div>
        <Form instance={user} action="edit" formAction="/users/password">
          <input type="hidden" name="commit" value="Change my password" />

          <HiddenField field="reset_password_token" value={reset_password_token} />
          <FormField field="password" type="password" label="Password" />
          <FormField field="password_confirmation" type="password" label="Password confirmation" />

          <div>
            <Button primary type="submit">Change my password</Button>
            </div>
            <LoginFlowLinks currentView="new-password" />

        </Form>

      </LoginFlowCard>
    </LoginFormWrapper>
  );
};
export default ForgotPasswordEdit;
