import RadioGroup from '@mui/material/RadioGroup'
import React from 'react'

export const RadioButtonGroup = ({children}) => {
  return (
    <RadioGroup>
      {children}
    </RadioGroup>
  )
}
