import React from "react";
import { useRailsContext } from "./utils";
import { twMerge } from "tailwind-merge";

export const Image = ({ src, alt, className, style }) => {
  const railsContext = useRailsContext();
  if (!src.startsWith("/") && !src.startsWith("http")) {
    src = `${railsContext.assets_path}/${src}`;
  }

  return <img src={src} alt={alt} className={className} style={style} />;
}

export const OmniAuthButton = ({ provider, children, className, style }) => {
  const railsContext = useRailsContext();
  const { csrf_token } = railsContext;

  return <form className="button_to" method="post" action={`/users/auth/${provider}`}>
      <button type="submit" className={className} style={style}>{children}</button>
      <input type="hidden" name="authenticity_token" value={csrf_token} autocomplete="off" />
    </form>
}

export const ShowLink = ({ children, className, instance, nestedInOrganization = true }) => {
  let href = `/${instance.__controller_name}/${instance.id}`;
  const { current_organization } = useRailsContext();
  if (current_organization && nestedInOrganization) {
    href = `/${current_organization.id}${href}`;
  }

  return <a href={href} className={twMerge("cursor-pointer text-primary-600 hover:text-primary-700 hover:underline font-semibold", className)}>
    {children}
  </a>
}

export const IndexLink = ({ children, className, to, nested = true }) => {
  let href = `/${to}`;
  const { current_organization } = useRailsContext();
  if (current_organization && nested) {
    href = `/${current_organization.id}${href}`;
  }

  return <a href={href} className={twMerge("cursor-pointer text-gray-500 hover:text-primary-600 hover:underline font-semibold", className)}>
    {children}
  </a>
}

export const Input = ({ className = null, ...props }) => {
  return <input {...props} className={twMerge("rounded-md border border-gray-300 outline-none px-3 py-1 disabled:bg-gray-50 disabled:opacity-60", className)} />
}

export const Select = ({ className = null, ...props }) => {
  return <select {...props} className={twMerge("bg-transparent focus:outline-none rounded-md border border-gray-300 outline-none px-3 py-1 pr-9 disabled:bg-gray-50 disabled:opacity-60", className)} />
}
