import React, { useState } from "react";
import { useForm } from "react-hook-form";
import FormLabel from "./components/form-label";
import LoginFlowLinks from "./components/login-flow-links";
import LoginFlowCard from "./components/login-flow-card";
import LoginFlowWrapper from "./components/login-flow-wrapper";
import {Button} from "@shared/form_fields";
import { useRailsContext } from "@shared/utils";
import { Image, OmniAuthButton } from "@shared/html_tags";

const NewSession = () => {
  const { csrf_token } = useRailsContext();
  const emailFromUrlParams = new URLSearchParams(window.location.search).get(
    "user[email]"
  );

  const [error, setError] = useState(null);
  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm();

  const onSubmit = handleSubmit(async (data) => {
    const formData = new FormData();
    formData.append('user[email]', data.email);
    formData.append('user[password]', data.password);
    // formData.append('user[remember_me]', data.rememberMe ? 1 : 0);
    formData.append('commit', 'Log in');

    fetch("/users/sign_in", {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrf_token,
        "X-Requested-With": "XMLHttpRequest"
      },
      body: formData,
    })
      .then((response) => response.ok ? response : Promise.reject(response))
      .then(() => location.replace(`/`)) // Send user to root on successful login
      .catch((response) => {
        response.text().then((text) => {
          setError(text); // Set error with the response's text content
          resetField("password");
        });
      });
  });

  return (
    <LoginFlowWrapper>
      <LoginFlowCard error={error}>
        <form onSubmit={onSubmit} className="flex flex-col gap-4">
          <div className="flex flex-col gap-0">
            <FormLabel label="Email" />
            <input
              type="email"
              name="email"
              className="rounded-md border-gray-400 py-3"
              placeholder="email"
              {...register("email", { value: emailFromUrlParams || "" })}
              required
            />
          </div>

          <div className="flex flex-col gap-0">
            <FormLabel label="Password" />
            <input
              type="password"
              name="password"
              className="rounded-md border-gray-400 py-3"
              placeholder="password"
              {...register("password")}
              required
              onChange={() => setError(null)}
            />
          </div>

          <LoginFlowLinks currentView="login" />

          {/* <div className="flex items-center gap-2">
            <input
              type="checkbox"
              name="rememberMe"
              className="rounded-sm p-2"
              placeholder="rememberMe"
              {...register("rememberMe")}
            />
            <FormLabel label="Remember Me" />
          </div> */}

          <div className="mt-2">
            <Button type="submit" className="w-full bg-sky-700">
              Login
            </Button>

          </div>

        </form>

        <p className="flex m-auto items-center">
          <div className="inline-block w-[20px] sm:w-[100px] h-[1px] bg-gray-400 mr-5"/>
          <div className="inline-block text-gray-700 whitespace-nowrap">or continue with Webex</div>
          <div className="inline-block w-[20px] sm:w-[100px] h-[1px] bg-gray-400 ml-5" />
        </p>

        <OmniAuthButton provider="webex" className="webex-button flex justify-center items-center border-2 border-sky-600 bg-white w-full h-12 text-sky-700 rounded-md mb-4">
          <Image src="webex-logo.png" className="mr-3" style={{width: "35px"}} alt="Webex Logo" />
          Sign in with Webex
        </OmniAuthButton>


      </LoginFlowCard>
    </LoginFlowWrapper>
  );
};
export default NewSession;
