import React from "react";
import { FormField, SelectField, ToggleField } from "@shared/form_fields";

export default function CustomFieldsForm() {
  return (
    <>
      <SelectField field="type" options={[{id: 'CustomEntityField', label: 'Entity'}, {id: 'CustomGroupField', label: 'Group'}]}/>
      <FormField field="name" />
      <FormField field="label" />
      <SelectField field="field_type" options={['toggle', 'number', 'text']}/>
      <ToggleField field="active" label="Show in table"/>
    </>
  )
}