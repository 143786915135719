import React from "react";
import { FormField, ToggleField } from "@shared/form_fields";

export default function GroupForm({ custom_fields }) {
  return (
    <>
      <FormField field="name" />
      {custom_fields?.map(field =>
        <span key={field.name}>
          {field.field_type == "toggle" && <ToggleField field={`custom_fields.${field.name}`} />}
          {field.field_type == "number" && <FormField type="number" field={`custom_fields.${field.name}`} />}
          {field.field_type == "text" && <FormField type="text" field={`custom_fields.${field.name}`} />}
        </span>
      )}
    </>
  )
}
