import React from "react";
import Flash from "@shared/flash";
import { Card } from "@shared/card";
import { Tabs, Tab } from "@shared/tabs";
import { IndexLink } from "@shared/html_tags";
import { useRailsContext } from "@shared/utils";

export default function OrganizationsIndex({organization}) {
  const { current_user } = useRailsContext();

  return (
    <>
      <Flash />

      <div className="flex justify-between items-center">
        <h1 className="font-semibold text-2xl text-slate-700">
          {organization.name}
        </h1>
      </div>

      {current_user["has_multiple_orgs?"] === true &&
        <IndexLink to="organizations" nested={false} className="block mt-1 mb-4 font-normal text-sm">&larr; Back to Organizations</IndexLink>
      }

      <Card className="mt-3">
        <Tabs className="">
          <Tab title="Some">
            <div className="flex justify-between p-6">
              Some stuff
            </div>
          </Tab>
          <Tab title="Other">
            <div className="flex justify-between p-6">
              Other stuff
            </div>
          </Tab>
        </Tabs>
      </Card>
    </>
  )
}
