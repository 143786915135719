import React, { useState } from "react";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { Modal } from "./modal";
import { Input, Select } from "./html_tags";
import { useStagedState } from "./hooks";

type FilterPillProps = {
  field: string;
  comparison: string;
  value: string;
  open: boolean;
  onApply: (field: string, comparison: string, value: string) => void;
  onClose: () => void;
  onRemove: () => void;
};

export const FilterPill = ({ field, comparison: initialComparison, value: initialValue, open: initialOpen, onApply, onClose, onRemove }: FilterPillProps) => {
  const [isModalOpen, setIsModalOpen] = useState(initialOpen);

  const [comparison, setComparison] = useState(initialComparison);
  const [value, setValue] = useState(initialValue || "");
  const {
    stagedValue: stagedComparison,
    setStagedValue: setStagedComparison,
    commit: commitComparison,
    isChangePending: isComparisonChangePending,
    reset: resetComparison,
  } = useStagedState<string>(comparison, setComparison);
  const {
    stagedValue: stagedValue,
    setStagedValue: setStagedValue,
    commit: commitValue,
    isChangePending: isValueChangePending,
    reset: resetValue,
  } = useStagedState<string>(value, setValue);

  const isChangePending = (isComparisonChangePending || isValueChangePending) && stagedValue !== "";

  const handleApply = () => {
    commitComparison();
    commitValue();
    onApply && onApply(field, stagedComparison, stagedValue);
    setIsModalOpen(false);
  }

  const handleCancel = () => {
    resetComparison();
    resetValue();
    onClose && onClose();
    setIsModalOpen(false);
  }

  return (
    <div className="inline-flex items-center rounded-full divide-x divide-gray-200 bg-gray-100 text-sm">
      <div onClick={() => setIsModalOpen(true)} className="cursor-pointer rounded-l-full pl-3 flex items-center space-x-1 py-2 pr-1 hover:bg-slate-200">
        <span className="font-bold">{field}</span>
        <span>{comparisonLabels[stagedComparison]}</span>
        <span>
            "<span className="font-bold">{stagedValue}</span>"
        </span>

        <button>
          <ChevronDownIcon className="h-4 w-4 text-gray-700" />
        </button>
      </div>

      <div onClick={onRemove} className="py-2 pl-1 pr-2 rounded-r-full bg-gray-100 hover:bg-red-100 group cursor-pointer">
        <button className="align-text-bottom ">
          <XMarkIcon className="h-4 w-4 text-gray-700 group-hover:text-red-700" />
        </button>
      </div>

      <Modal show={isModalOpen} onClose={handleCancel}>
        <div className="p-8 flex flex-col gap-3 text-lg">
          <div className="font-bold">{field}</div>
          <Select value={stagedComparison} onChange={(e) => setStagedComparison(e.target.value)}>
            <option value="eq">is</option>
            <option value="not_eq">is not</option>
            <option value="lt">is less than</option>
            <option value="lte">is less than or equal to</option>
            <option value="gt">is greater than</option>
            <option value="gte">is greater than or equal to</option>
            <option value="contains">contains</option>
            <option value="not_contains">does not contain</option>
            <option value="start">starts with</option>
            <option value="end">ends with</option>
            <option value="true">is true</option>
            <option value="false">is false</option>
            <option value="blank">is blank</option>
            <option value="not_blank">is not blank</option>
          </Select>

          <Input type="text" value={stagedValue} onChange={(e) => setStagedValue(e.target.value)} />
        </div>

        <section className="modal-footer p-4 flex justify-stretch gap-2 items-center">

            <button onClick={handleCancel} className="rounded-lg py-2 px-8 bg-gray-200 text-black block font-medium w-full">
              Cancel
            </button>

            <button disabled={!isChangePending} onClick={handleApply} className="rounded-lg py-2 px-8 bg-primary-700 text-white block font-medium w-full disabled:opacity-50">
              Apply
            </button>

          </section>
      </Modal>

    </div>
  );
}

const comparisonLabels = {
  eq: "is",
  not_eq: "is not",
  lt: "is less than",
  lte: "is less than or equal to",
  gt: "is greater than",
  gte: "is greater than or equal to",
  contains: "contains",
  not_contains: "does not contain",
  start: "starts with",
  end: "ends with",
  true: "is true",
  false: "is false",
  blank: "is blank",
  not_blank: "is not blank",
};
