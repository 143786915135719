/**
 * Utility hooks for filtering, sorting, and paginating arrays
 * and keeping the state in sync with the URL
 */

import { useState, useEffect, useMemo, useRef } from 'react';
import { getNestedValueFromString, useEffectAfterMount, useRailsContext } from './utils';

export type SortOrder = "asc" | "desc"

export type Sort = {
  by: string,
  order: SortOrder
}

export type Paging = {
  current: number,
  perPage: number
}

// Type definition for the below useFilterSortAndPaginateParamsFromURL hook
// Separated to make it easer to read
type UseFilterSortAndPaginateParamsFromURLFunction = (options: {
  defaultSortBy?: string,
  defaultSortOrder?: SortOrder
  defaultPage?: number,
  defaultPageSize?: number,
  defaultFilters?: [any],
  defaultRange?: { start: Date | null, end: Date | null }
  saveChanges?: boolean
}) => {
  sort: Sort,
  setSort: (sort: Sort) => void,
  paging: Paging,
  setPaging: (paging: Paging) => void
}

export const useQueryParams = (paramsList) => {
  let initialParams = {};
  for (const key in paramsList) {
    if (paramsList[key] && paramsList[key].default) {
      if (typeof paramsList[key].default === "string") {
        switch (paramsList[key].type) {
          case "datetime":
            initialParams[key] = new Date(paramsList[key].default);
            break;
          case "object":
            initialParams[key] = JSON.parse(paramsList[key].default);
            break;
          default:
            initialParams[key] = paramsList[key].default;
        }
      } else {
        initialParams[key] = paramsList[key].default;
      }
    }
  }

  const { query_params } = useRailsContext();
  for (const key in query_params) {
    if (paramsList[key]) {
      switch (paramsList[key].type) {
        case "number":
          initialParams[key] = parseFloat(query_params[key]);
          break;
        case "datetime":
          initialParams[key] = new Date(query_params[key]);
          break;
        case "object":
          initialParams[key] = JSON.parse(query_params[key]);
          break;
        default:
          initialParams[key] = query_params[key];
      }
    }
  }

  const [params, setParams] = useState(initialParams);
  const setSomeParams = (newParams) => {
    setParams({ ...params, ...newParams });
  }

  useEffectAfterMount(() => {
    const queryParams = new URLSearchParams();
    for (const key in params) {
      if (params[key] !== undefined && params[key] !== null && params[key].toString().trim() !== "") {
        if (params[key] === paramsList[key].default && query_params[key] === undefined) {
          continue;
        }

        switch (paramsList[key].type) {
          case "datetime":
            queryParams.set(key, params[key].toISOString());
            break;
          case "object":
            queryParams.set(key, JSON.stringify(params[key]));
            break;
          default:
            queryParams.set(key, params[key]);
        }
      }
    }
    const queryString = queryParams.toString();
    if (queryString) {
      window.history.replaceState(null, '', `${window.location.pathname}?${queryString}`);
    } else {
      window.history.replaceState(null, '', `${window.location.pathname}`);
    }
  }, [params]);

  return [params, setSomeParams];
}

/**
 * This hook will load the sortBy, sortOrder, currentPage, and pageSize from the URL
 * and keep them in sync with the URL as they change
 */
export const useFilterSortAndPaginateParamsFromURL : UseFilterSortAndPaginateParamsFromURLFunction = (options) => {
  let {
    defaultSortBy = "id",
    defaultSortOrder = "asc",
    defaultPage = 1,
    defaultPageSize = 50,
    defaultFilters = [],
    defaultRange = { start: null, end: null },
    saveChanges = true
  } = options;

  const { query_params } = useRailsContext();
  if (query_params) {
    const params = new URLSearchParams(query_params);
    if (params.has('sort_by')) {
      defaultSortBy = params.get('sort_by');
    }
    if (params.has('sort_order')) {
      defaultSortOrder = params.get('sort_order') as SortOrder;
    }
    if (params.has('page')) {
      defaultPage = parseInt(params.get('page'));
    }
    if (params.has('per_page')) {
      defaultPageSize = parseInt(params.get('per_page'));
    }
    if (params.has('filters')) {
      defaultFilters = JSON.parse(params.get('filters'));
    }
    if (params.has('start') && params.has('end')) {
      defaultRange.start = new Date(params.get('start'));
      defaultRange.end = new Date(params.get('end'));
    }
  }

  const [sort, setSort] = useState({ by: defaultSortBy, order: defaultSortOrder });
  const [paging, setPaging] = useState({ current: defaultPage, perPage: defaultPageSize });
  const [filters, setFilters] = useState(defaultFilters);
  const [range, setRange] = useState(defaultRange);

  if (saveChanges) {
    useSaveParamsInURLEffect({ sort, paging, filters, range });
  }

  const setRangeWithPageReset = (newRange) => {
    setRange(newRange);
    setPaging({ current: 1, perPage: paging.perPage });
  }

  return {
    sort,
    setSort,
    paging,
    setPaging,
    filters,
    setFilters,
    range,
    setRange: setRangeWithPageReset
  }
}

/**
 * This effect will save the current sortBy, sortOrder, currentPage, and pageSize to the URL
 * so that the user can share the current view via a link
 */
export const useSaveParamsInURLEffect = ({ sort, paging, filters, range }) => {
  const { by: sortBy, order: sortOrder } = sort;
  const { current: currentPage, perPage: pageSize } = paging;
  const { start, end } = range;
  // console.warn("useSaveParamsInURLEffect", sortBy, sortOrder, currentPage, pageSize);

  // Doesn't run on first render
  // Only change the URL if the user has interacted with the table
  useEffectAfterMount(() => {
    const params = new URLSearchParams();
    if (sortBy) params.set('sort_by', sortBy);
    if (sortOrder) params.set('sort_order', sortOrder);
    if (currentPage) params.set('page', currentPage.toString());
    if (pageSize) params.set('per_page', pageSize.toString());
    if (filters && filters.length > 0) params.set('filters', JSON.stringify(filters));
    if (start && end) {
      params.set('start', start.toISOString());
      params.set('end', end.toISOString());
    }

    const queryString = params.toString();
    if (queryString) {
      window.history.replaceState(null, '', `${window.location.pathname}?${queryString}`);
    }
  }, [sortBy, sortOrder, currentPage, pageSize, filters, start, end]);
}

/**
 * This hook will take an array and return a paged, sorted, and filtered array (filtering not yet implemented)
 * based on the current sortBy, sortOrder, currentPage, and pageSize
 */
export const useFilteredSortedPaginatedArray = ({
  array,
  sort,
  paging,
  sortFunctions = {}
}) => {
  const { by: sortBy, order: sortOrder } = sort;
  const { current: currentPage, perPage: pageSize } = paging;

  const visibleArray = useMemo(() => {
    // console.warn("sorting array", sortBy, sortOrder, array);
    if (!sortBy) return array;

    let sortByFn = sortBy;
    if (typeof sortByFn !== 'function') {
      if (sortFunctions[sortBy]) {
        sortByFn = sortFunctions[sortBy];
      } else if (sortBy.includes('.')) {
        sortByFn = (row) => {
          return getNestedValueFromString(row, sortBy);
        }
      } else {
        sortByFn = (row) => row && row[sortBy];
      }
    }

    // Use the collator to sort strings case insensitive with international character support
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare#Performance
    const collator = new Intl.Collator();
    const sortedArray = [...array].sort((a, b) => {
      const aValue = sortByFn(a) || "";
      const bValue = sortByFn(b) || "";
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        const comparison = collator.compare(aValue, bValue);
        if (comparison !== 0) {
          return sortOrder === 'asc' ? comparison : -comparison;
        }
      }
      if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });

    const page = currentPage || 1;
    const perPage = pageSize || 100;

    const startIndex = (page - 1) * perPage;
    return sortedArray.slice(startIndex, startIndex + perPage);
  }, [array, sortBy, sortOrder, sortFunctions, currentPage, pageSize]);

  return visibleArray;
}
