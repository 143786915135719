import React, { useMemo } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon, XMarkIcon, PlusIcon } from "@heroicons/react/20/solid";
import { FormModalLink, ShowFormModalButton, EditFormModalButton, NewFormModalButton } from "@shared/modal";
import { DeleteButton } from "@shared/form_fields";
import { Table, TableEmpty, Column } from "@shared/tables/table";
import { AutoTable } from "@shared/tables/auto_table";
import { Pagination } from "@shared/tables/pagination";
import DatePicker from "@shared/date_picker";
import Flash from "@shared/flash";
import { Card } from "@shared/card";
import { ShowLink } from "@shared/html_tags";
import { useQueryParams } from "@shared/filter_sort_paginate";
import { useAjax } from "@shared/ajax";
import { useRailsContext, useEffectAfterMount } from "@shared/utils";
import { Search } from "@shared/search";
import EntityStatus from "./entity-status";
import ToggleWithConfirmation from "@shared/toggle-with-confirmation";

export default function EntityIndex({ data, time_zones, custom_fields, groups, schedules, holiday_lists }) {
  const { entity_name } = useRailsContext();

  const [params, setParams] = useQueryParams({
    sort_by: { default: "name" },
    sort_order: { default: "asc" },
    page: { type: "number", default: 1 },
    per_page: { type: "number", default: 50 },
    filters: { type: "object", default: [] },
    search: { type: "string", default: "" }
  });

  const {
    sort_by,
    sort_order,
    page,
    per_page,
    filters,
    search
  } = params;

  // useEffectAfterMount(() => {
  //   setParams({ page: 1 });
  // }, [filters, start, end]);

  const ajax = useAjax(data);
  const entities = ajax.data.items;

  const filteredEntities = useMemo(() => {
    if (!search) return entities;
    return entities.filter(entity => entity.name.toLowerCase().includes(search.toLowerCase()));
  }, [ajax.data, search]);

  // const ajax = useAjax(data, { params });
  // const {
  //   items: entities,
  //   total_pages
  // } = ajax.data;

  // const setFilters = (newFilters) => setParams({ filters: newFilters });

  return (
    <>
      {/* <Flash /> */}

      <div className="flex justify-between items-center">
        <h1 className="font-semibold text-2xl text-slate-700">
          {entity_name.plural}
        </h1>

      </div>

      <Card className="mt-3 pb-6">
        <div id="entities" className="flex justify-between p-8">
          <div className="flex grow">
            <Search value={search} items={entities.map((entity) => entity.name)} onChange={(search) => setParams({search})} className="z-10 w-4/5 md:w-3/5" />
          </div>

          <div>
            <NewFormModalButton className="pl-3" ajax={true} onSubmit={ajax.refresh} closeOnSubmit={true}
              label={`New ${entity_name.singular}`} title={`New ${entity_name.singular}`}
              props={{time_zones, groups, schedules, holiday_lists}}
            />
          </div>
        </div>

        {/* <Table */}
        <AutoTable data={filteredEntities} loading={ajax.loading}
            sortBy={sort_by} sortOrder={sort_order}
            onSortChanged={(sortBy, sortOrder) => { setParams({ sort_by: sortBy, sort_order: sortOrder }) }}
            currentPage={page} pageSize={per_page}
            onPageChanged={page => setParams({ page })}
        >
          <TableEmpty>
            <p className="p-6 text-gray-900"><i>No entities created yet</i></p>
          </TableEmpty>

          <Column title="Status" field="status" sortFn={entity => entity.status?.is_open}>
            {entity => <EntityStatus entity={entity} />}
          </Column>
          <Column title="Name" field="name">
            {entity => <ShowLink instance={entity}>{entity.name}</ShowLink>}
          </Column>
          <Column title="Force Closed" field="force_closed">
            {entity =>
              <ToggleWithConfirmation instance={entity} fieldName="force_closed" fieldLabel="Force closed"
                checked={entity.force_closed}
                onConfirm={() => ajax.update(entity, {
                  force_closed: !entity.force_closed
                })}
                getConfirmationMessage={checked =>
                  <>Are you sure you want to <strong>{checked ? "open" : "close"}</strong> "{entity.name}"?</>
                }
                getButtonLabel={checked =>
                  checked ? "Open" : "Force Closed"
                }
              />
            }
          </Column>
          <Column title="Code" field="code" blankValue="-" />
          <Column title="Group" field="group" >
            {(entity) => {
              const group = groups.find(g => g.id === entity.group_id);
              return group ? (group.name || group.id) : "-";
            }}
          </Column>
          <Column title="Schedule" field="schedule" >
            {(entity) => {
              const schedule = schedules.find(g => g.id === entity.schedule_id);
              return schedule ? (schedule.name || schedule.id) : "-";
            }}
          </Column>
          <Column title="Holiday List" field="holiday_list" >
            {(entity) => {
              const holiday_list = holiday_lists.find(g => g.id === entity.holiday_list_id);
              return holiday_list ? (holiday_list.name || holiday_list.id) : "-";
            }}
          </Column>
          {/* <Column title="Time Zone" field="time_zone" blankValue={<i>Default</i>} /> */}

          {custom_fields?.filter(field => field.active === true).map(field =>
            <Column key={field.name} title={field.label} blankValue="-" field={`custom_fields.${field.name}`}>
              {entity => {
                if (field.field_type == "toggle") {
                  return <ToggleWithConfirmation instance={entity} fieldName={field.name} fieldLabel={field.label}
                    checked={entity.custom_fields && entity.custom_fields[field.name]}
                    onConfirm={() => ajax.update(entity, {
                      custom_fields: { [field.name]: !entity.custom_fields[field.name] }
                    })}
                  />
                }

                return <span>
                  {(entity.custom_fields &&
                    entity.custom_fields[field.name])}
                </span>
              }}
            </Column>
          )}

          <Column sortable={false} style={{width: "1%"}}>
            {entity =>
              <div className="flex gap-3 items-center">
                {/* You'll want to check for actual permissions for this */}
                <EditFormModalButton instance={entity} className="w-24"
                  title={`Edit ${entity_name.singular}`}
                  ajax={true} onSubmit={ajax.refresh} closeOnSubmit={true}
                  props={{time_zones, groups, schedules, holiday_lists}}
                />
                <DeleteButton instance={entity} className="w-24" ajax={true} onDelete={ajax.refresh}
                  modelLabel={entity_name.singular}
                />
              </div>
            }
          </Column>

        </AutoTable>
        {/* </Table> */}

        {/* <div className="flex items-center justify-between bg-white mt-4 pr-8 sm:rounded-b-lg">
          <Pagination currentPage={page} totalPages={total_pages} onPageChange={(page) => setParams({ page: page })} className="sm:rounded-b-lg" />
        </div> */}
      </Card>
    </>
  )
}
