export const ApplicationNames = [
  { id: 1, name: "Entity", plural: "Entities" },
  { id: 2, name: "Location", plural: "Locations" },
  { id: 3, name: "Branch", plural: "Branches" },
  { id: 4, name: "Store", plural: "Stores" },
  { id: 5, name: "Department", plural: "Departments" },
  { id: 6, name: "Unit", plural: "Units" },
  { id: 7, name: "Division", plural: "Divisions" },
  { id: 8, name: "Section", plural: "Sections" },
  { id: 9, name: "Area", plural: "Areas" },
  { id: 10, name: "Office", plural: "Offices" },
  { id: 11, name: "Facility", plural: "Facilities" },
  { id: 12, name: "Queue", plural: "Queues" },
  { id: 13, name: "Site", plural: "Sites" },
];

export const ReturnPluralValues = (name) => {
  return ApplicationNames.find((a) => a.name === name)?.plural;
};

export const DefaultApplicationName = () => {
  return ApplicationNames.find((a) => a.id === 13);
};
