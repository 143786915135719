import React, { useState } from "react";
import { FormField, CheckboxField, SelectField, useFormContext, Checkbox, HiddenField, ToggleField } from "@shared/form_fields";
import { format, toZonedTime } from "date-fns-tz";

export default function RegularScheduleForm({schedule_id}) {
  const { instance } = useFormContext();

  return (
    <>
      <HiddenField field="schedule_id" value={schedule_id} />

      <div className="flex w-full justify-between text-nowrap">
        <CheckboxField field="days.mon" label="Mon" className="inline-block" />
        <CheckboxField field="days.tue" label="Tue" className="inline-block" />
        <CheckboxField field="days.wed" label="Wed" className="inline-block" />
        <CheckboxField field="days.thu" label="Thu" className="inline-block" />
        <CheckboxField field="days.fri" label="Fri" className="inline-block" />
        <CheckboxField field="days.sat" label="Sat" className="inline-block" />
        <CheckboxField field="days.sun" label="Sun" className="inline-block" />
      </div>

      <div className="flex gap-2">
        <FormField field="start_time" type="time" className="grow"
          value={format(toZonedTime(new Date(instance.start_time || "2000-01-01T00:00:00.000Z"), "UTC"), "HH:mm")}
        />
        <FormField field="end_time" type="time" className="grow"
          value={format(toZonedTime(new Date(instance.end_time || "2000-01-01T23:59:59.999Z"), "UTC"), "HH:mm")}
        />
      </div>

    </>
  )
}


