import React from "react";
import { useRailsContext } from "./utils";
import { twMerge } from 'tailwind-merge'
import { CsrfToken, FormContext } from "./form_fields";

export const Form = ({
  children,
  action = "show",
  formAction,
  method,
  model,
  controller,
  submitButtonLabel,
  instance = {},
  props = {},
  onSubmit,
  readOnly,
  ajax,
  handleCancel = () => {},
}) => {
  const railsContext = useRailsContext();

  const [disabled, setDisabled] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [submitted, setSubmitted] = React.useState(false);
  const [result, setResult] = React.useState(null);

  const reset = () => {
    setLoading(false);
    setError(null);
    setSubmitted(false);
    setResult(null);
    setDisabled(false);
  }

  const enable = () => setDisabled(false);
  const disable = () => setDisabled(true);

  const handleSubmit = async (event) => {
    if (!ajax) {
      // If not using AJAX, let the form submit normally
      if (onSubmit) {
        onSubmit(event);
      }
      return;
    }

    event.preventDefault(); // Prevent default form submission only for AJAX

    const formData = new FormData(event.target);
    const jsonBody = {};
    // Formats the form data as JSON objects per Rails naming convention
    // e.g., "user[name]" becomes `{ user: { name: "John" } }`
    // @ts-ignore
    for (let [key, value] of formData.entries()) {
      // Split the key based on Rails naming convention (e.g., "user[name]")
      const match = key.match(/(\w+)\[(\w+)\]/);
      if (match) {
        const [, parentKey, childKey] = match;
        if (!jsonBody[parentKey]) {
          jsonBody[parentKey] = {};
        }
        jsonBody[parentKey][childKey] = value;
      } else {
        // Handle keys that don't use the Rails naming convention (e.g., just "name")
        jsonBody[key] = value;
      }
    }

    const method = formData.get('_method') || 'post';

    try {
      setLoading(true);
      const response = await fetch(event.target.action, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "X-CSRF-Token": railsContext.csrf_token
        },
        body: JSON.stringify(jsonBody)
      });
      const result = await response.json();

      if (!response.ok) {
        console.warn(`Error submitting via ajax: ${response.status} - ${response.statusText}`, result);
        setLoading(false);
        setError(result);
        setSubmitted(true);
        return;
      }

      setLoading(false);
      setSubmitted(true);
      setResult(result);
      if (onSubmit) {
        onSubmit(result);
      }
      if (closeOnSubmit) {
        handleCancel();
      }

    } catch (error) {
      console.error("Error submitting via ajax", error);
      setLoading(false);
      setError(error);
      setSubmitted(true);
    }
  };

  if (!model) {
    model = instance.__model_name || railsContext.model_name;
  }

  if (readOnly === undefined) {
    readOnly = (action === "show");
  }

  if (!method) {
    method = "post";
    if (action === "edit" || action === "show") {
      method = "put";
    }
  }

  if (!controller) {
    controller = instance.__controller_name || railsContext.controller_name;
  }

  if (!formAction) {
    formAction = `/${controller}`
    if (action === "edit" || action === "show") {
      formAction += `/${instance.id}`
    }

    if (railsContext.current_organization?.id) {
      formAction = `/${railsContext.current_organization.id}${formAction}`
    }
  }

  // If no children are passed in, we'll try to render a form component based on the controller
  // using the convention that the form component is located at `app/javascript/components/${controller}/form.jsx`
  if (React.Children.count(children) === 0) {
    let componentPath = `${controller}/form`;
    const FormComponent = ReactRailsUJS.getConstructor(componentPath);
    children = <FormComponent {...props} />;
  }

  return (
    <form action={formAction} method="POST" onSubmit={handleSubmit}>
      <FormContext.Provider value={{ model, action, readOnly, instance, loading, submitted, error, reset, enable, disable, result, close: handleCancel }}>
        <input name="_method" type="hidden" value={method} />
        <CsrfToken />
        {children}
      </FormContext.Provider>
    </form>
  )
}

// export interface FormContextType {
//   model: string,
//   action: string,
//   readOnly: boolean,
//   instance: any,
//   shown: boolean,
//   loading: boolean,
//   reset: () => void,
//   submitted: boolean,
//   error: any,
//   result: any,
//   enable: () => void,
//   disable: () => void,
//   close: () => void
// }
// export const FormContext = React.createContext<FormContextType>({
//   model: "",
//   action: "",
//   readOnly: false,
//   loading: false,
// });
// export const useFormContext = () => React.useContext(FormContext) as FormContextType;

// export const CsrfToken = () => {
//   const railsContext = useRailsContext();
//   return <input type="hidden" name="authenticity_token" value={railsContext.csrf_token} />
// }
