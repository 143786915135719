import React from 'react'
import { twMerge } from 'tailwind-merge';

const ABBREVIATION_OFFSET = 3;
const ABBREVIATION_LENGTH = 2;

export const Pagination = (props) => {
  let shouldAbbreviate = props.totalPages > 10;
  let shouldAbbreviateStart = shouldAbbreviate && props.currentPage >= 5;
  let shouldAbbreviateEnd = shouldAbbreviate && props.currentPage <= props.totalPages - 4;
  let shouldAbbreviateBoth = shouldAbbreviateStart && shouldAbbreviateEnd;

  let nextEnabled = props.currentPage < props.totalPages;
  let prevEnabled = props.currentPage > 1;

  return (
    <div className={twMerge("flex flex-1 items-center justify-center md:justify-end", props.className)}>
      <nav className="isolate inline-flex -space-x-px" aria-label="Pagination">

        <a href="#" tabIndex="0" className={`${!prevEnabled ? "cursor-default text-gray-400" : "text-gray-900"} relative inline-flex items-center py-4 pr-5 md:py-2 md:pr-3 border-b-4 border-b-white`}
          onClick={(e) => { prevEnabled && props.onPageChange && props.onPageChange(props.currentPage - 1); e.preventDefault(); }}
        >
          Prev
          {/* left chevron, https://heroicons.com/mini */}
          {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
            <path fillRule="evenodd" d="M11.78 5.22a.75.75 0 0 1 0 1.06L8.06 10l3.72 3.72a.75.75 0 1 1-1.06 1.06l-4.25-4.25a.75.75 0 0 1 0-1.06l4.25-4.25a.75.75 0 0 1 1.06 0Z" clipRule="evenodd" />
          </svg> */}
        </a>

        {/* loop through pages up to props.totalPages */}
        {(() => {
          let pages = [];
          for (let i = 1; i <= props.totalPages; i++) {
            if (shouldAbbreviateStart && !shouldAbbreviateEnd && i > 2 && i < props.totalPages - 4) {
              continue;
            } else if (shouldAbbreviateEnd && !shouldAbbreviateStart && i <= props.totalPages - 2 && i > 5) {
              continue;
            } else if (shouldAbbreviateBoth && i > props.currentPage + 1 && i < props.totalPages - 1) {
              continue;
            } else if (shouldAbbreviateBoth && i < props.currentPage - 1 && i > 2) {
              continue;
            } else if ((shouldAbbreviateStart && i == 2) || (shouldAbbreviateEnd && i == props.totalPages - 1)) {
              pages.push(
                  <a key={i} tabIndex="0" className="relative inline-flex items-center py-4 px-4 md:py-2 md:px-4 font-semibold text-gray-900">
                    …
                  </a>
              );
            } else {
              pages.push(


          // active "relative z-10 inline-flex items-center bg-primary-500 px-4 py-2 font-semibold text-white                                                      focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500"
                // "relative      inline-flex items-center               px-4 py-2 font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  <a href="#" tabIndex="0" key={i} className={`${props.currentPage === i ? "border-b-4 border-b-primary-500" : "border-b-4 border-b-white hover:border-b-gray-200 text-gray-900"} relative inline-flex items-center px-3 py-2 font-semibold`}
                    onClick={(e) => { props.onPageChange && props.onPageChange(i); e.preventDefault(); }}
                  >
                    {i}
                  </a>

              );
            }
          }
          return pages;
        })()}

        <a href="#" tabIndex="0" className={`${!nextEnabled ? "cursor-default text-gray-400" : "text-gray-900"} relative inline-flex items-center py-4 pl-5 md:py-2 md:pl-3 border-b-4 border-b-white`}
          onClick={(e) => {nextEnabled && props.onPageChange && props.onPageChange(props.currentPage + 1); e.preventDefault(); }}
        >
          Next
          {/* right chevron https://heroicons.com/mini */}
          {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
            <path fillRule="evenodd" d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
          </svg> */}
        </a>

      </nav>
    </div>
  );
}
