import { useState } from "react";
import EventEmitter from "eventemitter3";
class AudioManager extends EventEmitter {
  private static instance: AudioManager;
  public audio: HTMLAudioElement;
  public id: string | null;

  private constructor() {
    super();

    this.audio = new Audio();
    this.id = null;

    this.audio.onended = () => {
      this.emit("ended", this.audio.src, this.id);
    }
  }

  static getInstance() {
    if (!AudioManager.instance) {
      AudioManager.instance = new AudioManager();
    }

    return AudioManager.instance;
  }

  reset() {
    if (this.audio) {
      this.emit("ended", this.audio.src, this.id);
      this.audio.pause();
      this.audio.currentTime = 0;
      this.audio.src = "";
    }
    this.id = null;
  }

  playAudio(audioSrc: string, id: string) {
    try {
      if (this.id !== id) {
        this.id = id;
        this.audio.src = audioSrc;
        this.play();
        this.emit("play", audioSrc, id);
      } else {
        if (this.audio.paused) {
          this.play();
          this.emit("play", audioSrc, id);
        } else {
          this.audio.pause();
          this.audio.currentTime = 0;
          this.emit("ended", audioSrc, id);
        }
      }
    } catch (error) {
      console.error("Error playing audio:", error);
    }
  }

  paused(): boolean {
    return this.audio.paused;
  }

  private play(): void {
    let playPromise = this.audio.play();
    if (playPromise !== undefined) {
      playPromise.then(_ => {
        // Playback started!
      })
      .catch(error => {
        // Auto-play was prevented
        console.warn("Error playing audio:", error);
      });
    }
  }

}
export default AudioManager.getInstance();
