import React, { useEffect, useState } from "react";
import TableDataGrid from "../TableDataGrid";
import { Autocomplete } from "../inputs/Autocomplete";
import axios from "axios";
import useUserStore from "../store/userStore";
import Button from "../inputs/Button";
import DeleteUserModal from "./DeleteUserModal";
import { Delete, Edit } from "@mui/icons-material";
import EditUserModal from "./EditUserModal";

axios.defaults.headers.common["Accept"] = "application/json";

const TableUser = () => {
  const users = useUserStore((state) => state.users);
  const getUsers = useUserStore((state) => state.getUsers);
  const [filterModel, setFilterModel] = useState<any>({ items: [] });

  useEffect(() => {
    getUsers();
  }, []);

  const onChange = (value) => {
    setFilterModel({
      items: [
        {
          field: "name",
          operator: "contains",
          value: value.target.value
            ? value.target.value
            : value.target.innerText,
        },
      ],
    });
  };

  return (
    <>
      <Autocomplete
        className="sm:w-[80%] md:w-[544px] mb-[30px]"
        open={false}
        onChange={onChange}
        options={users?.map((item) => item.first_name)
          .filter((value, index, self) => self.indexOf(value) === index)}
      />
      <TableDataGrid
        height={685}
        rows={users}
        filterModel={filterModel}
        columns={[
          {
            field: "first_name",
            headerName: "First name",
            flex: 0.3,
            cellClassName: "!flex !items-center !gap-x-2 text-left",
          },
          {
            field: "last_name",
            headerName: "Last name",
            flex: 0.3,
            cellClassName: "!flex !items-center !gap-x-2 text-left",
          },
          {
            field: "email",
            headerName: "Email",
            flex: 0.3,
            cellClassName: "!flex !items-center !gap-x-2 text-left",
          },
          {
            field: "actions",
            headerName: "Actions",
            flex: 0.4,
            cellClassName: "!text-center",
            renderCell: (props) => {
              const [openDelete, setOpenDelete] = useState(false);
              const handleDeleteOpen = () => setOpenDelete(!openDelete);
              const [openEdit, setOpenEdit] = useState(false);
              const handleEditOpen = () => setOpenEdit(!openEdit);

              return (
                <div className="!text-left !flex !gap-x-2 !flex-row">
                  <DeleteUserModal
                    open={openDelete}
                    user={props.row}
                    onClose={handleDeleteOpen}
                  />

                  <EditUserModal
                    open={openEdit}
                    userSelected={props.row}
                    onClose={handleEditOpen}
                  />

                  <Button
                    variant="contained"
                    onClick={handleEditOpen}
                    className="!bg-[#2f6c8b] !border-2 !h-8 !w-28 !capitalize !font-normal"
                    endIcon={<Edit />}
                  >
                    Edit
                  </Button>

                  <Button
                    variant="outlined"
                    onClick={handleDeleteOpen}
                    className="!border-[#2f6c8b] !text-[#2f6c8b] !border-2 !h-8 !w-28 !capitalize !font-normal"
                    endIcon={<Delete />}
                  >
                    Delete
                  </Button>
                </div>
              );
            },
          },
        ]}
        sort={[{ field: "name", sort: "asc" }]}
      />
    </>
  );
};

export default TableUser;
