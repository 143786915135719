import { create } from "zustand";
import { getUsers } from "../services/userService";
import { User } from "../model/user";

interface UserState {
  users: User[];
  getUsers: () => void;
}

const useUserStore = create<UserState>((set) => ({
  users: [],
  getUsers: async () => {
    const response = await getUsers();
    set({ users: response });
  },
}));

export default useUserStore;
