import React from "react";
import { EditFormModalButton, NewFormModalButton } from "@shared/modal";
import { DeleteButton } from "@shared/form_fields";
import { TableEmpty, Column } from "@shared/tables/table";
import { AutoTable } from "@shared/tables/auto_table";
import Flash from "@shared/flash";
import { Card } from "@shared/card";
import { ShowLink } from "@shared/html_tags";
import { useRailsContext } from "@shared/utils";

export default function OrganizationsIndex({organizations}) {
  const { current_user } = useRailsContext();

  return (
    <>
      <Flash />

      <div className="flex justify-between items-center">
        <h1 className="font-semibold text-2xl text-slate-700">
          Organizations
        </h1>

      </div>

      <Card className="mt-3">
        <div id="organizations" className="flex justify-between p-6">
          <div>
            <span>Search here</span>
          </div>
          <div>
            <NewFormModalButton className="pl-3" label="New Organization" />
          </div>
        </div>

        <AutoTable data={organizations} sortBy="id">
          <TableEmpty>
            <p className="p-6 text-gray-900"><i>No organizations created yet</i></p>
          </TableEmpty>

          <Column title="ID" field="id"/>
          <Column title="Name" field="name" sortField="name">
            {org => <ShowLink instance={org}>{org.name}</ShowLink>}
          </Column>
          <Column title="Time Zone" field="time_zone"/>
          <Column sortable={false} style={{width: "1%"}}>
            {org =>
              <div className="flex gap-3 items-center">
                <EditFormModalButton instance={org} className="w-24" ajax={false} />
                {current_user.master_admin &&
                  <DeleteButton instance={org} className="w-24" />
                }
              </div>
            }
          </Column>

        </AutoTable>

      </Card>
    </>
  )
}
