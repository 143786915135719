import React from "react";
import { EditFormModalButton, NewFormModalButton } from "@shared/modal";
import { DeleteButton } from "@shared/form_fields";
import { Table, TableEmpty, Column } from "@shared/tables/table";
import Flash from "@shared/flash";
import { Card } from "@shared/card";
import { useAjax } from "@shared/ajax";
import { useQueryParams } from "@shared/filter_sort_paginate";
import { Pagination } from "@shared/tables/pagination";

export default function ApiKeysIndex({ data, ...props}) {
  const [params, setParams] = useQueryParams({
    sort_by: { default: props.sort_by },
    sort_order: { default: props.sort_order },
    page: { type: "number", default: props.page },
    per_page: { type: "number", default: props.per_page },
    filters: { type: "object", default: [] }
  });

  const {
    sort_by,
    sort_order,
    page,
    per_page,
    filters
  } = params;

  const ajax = useAjax(data, { params });

  const {
    items: api_keys,
    total_pages
  } = ajax.data;

  return (
    <>
      <Flash />

      <div className="flex justify-between items-center">
        <h1 className="font-semibold text-2xl text-slate-700">
          API Keys
        </h1>

      </div>

      <Card className="mt-3 pb-6">
        <div id="api_keys" className="flex justify-between p-8">
          <div>
            <span>Search here</span>
          </div>
          <div>
            <NewFormModalButton className="pl-3" label="New API Key" ajax={true} onSubmit={ajax.refresh} />
          </div>
        </div>

        <Table data={api_keys} sortBy={sort_by} sortOrder={sort_order} onSort={({by, order}) => setParams({ sort_by: by, sort_order: order })} loading={ajax.loading}>
          <TableEmpty>
            <p className="p-6 text-gray-900"><i>No API keys created yet</i></p>
          </TableEmpty>

          <Column title="Name" field="name" />
          <Column title="ID" field="id" />
          <Column title="Last Used" field="last_used_at" sortField="last_used_at">
            {api_key => api_key.last_used_at ? new Date(api_key.last_used_at).toLocaleString() : <i className="text-gray-500">Never</i>}
          </Column>

          <Column sortable={false} style={{width: "1%"}}>
            {api_key =>
              <div className="flex gap-3 items-center">
                <EditFormModalButton buttonText="Rename" instance={api_key} title="Rename API Key" className="w-28"
                    ajax={true} onSubmit={ajax.refresh} closeOnSubmit={true}  />
                <DeleteButton instance={api_key} className="w-24" ajax={true} onDelete={ajax.refresh} />
              </div>
            }
          </Column>

        </Table>

        <div className="flex items-center justify-between bg-white mt-4 pr-8 sm:rounded-b-lg">
          <Pagination currentPage={page} totalPages={total_pages} onPageChange={(page) => setParams({ page })} className="sm:rounded-b-lg" />
        </div>
      </Card>
    </>
  )
}
