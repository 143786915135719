import React, { useState, useRef } from "react";
import { Button, FormField, HiddenField, ToggleField, useFormContext } from "@shared/form_fields";
import { twMerge } from "tailwind-merge";


export default function PromptForm({entity_id}) {
  const { instance: prompt } = useFormContext();

  const [audioEnFilename, setAudioEnFilename] = useState(null);
  const [audioEsFilename, setAudioEsFilename] = useState(null);

  return (
    <>
      {entity_id && <HiddenField field="entity_id" value={entity_id} />}

      <FormField field="name" />
      <FormField field="description" />

      <div className="flex flex-col gap-2">
        <div>
          <UploadFileField name="prompt[audio_en]"
            label="English Audio"
            buttonLabel={prompt.audio_en_filename || audioEnFilename ? "Select New File" : "Select File"}
            onChange={(fileName) => setAudioEnFilename(fileName)}
            className="bg-white hover:bg-primary-50 border border-dashed border-primary-600 text-primary-600 w-full"
          />
          <span className="text-xs text-gray-700">{audioEnFilename ?
            "File: " + audioEnFilename
            : prompt.audio_en_filename ?
              "Existing: " + prompt.audio_en_filename
              : "No file selected"}
          </span>
        </div>

        <div>
          <UploadFileField name="prompt[audio_es]"
            label="Spanish Audio"
            buttonLabel={prompt.audio_es_filename || audioEsFilename ? "Select New File" : "Select File"}
            onChange={(fileName) => setAudioEsFilename(fileName)}
            className="bg-white hover:bg-primary-50 border border-dashed border-primary-600 text-primary-600 w-full"
          />
          <span className="text-xs text-gray-700">{audioEsFilename ?
            "File: " + audioEsFilename
            : prompt.audio_es_filename ?
              "Existing: " + prompt.audio_es_filename
              : "No file selected"}
          </span>
        </div>
      </div>

    </>
  )
}


function UploadFileField({name, label, buttonLabel = "Select File", className, onChange = () => {}}) {
  const [fileName, setFileName] = useState('');
  const fileInputRef = useRef();

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      onChange(file.name);
    }
  };

  return (
    <div>
      <label htmlFor={name} className="block">{label}</label>
      <Button type="button" className={className}
          onClick={handleButtonClick}
      >
        {buttonLabel}
      </Button>
      <input
        name = {name}
        type="file"
        ref={fileInputRef}
        accept="audio/*"
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      {/* {fileName && <p>File: {fileName}</p>} */}
    </div>
  );
}
