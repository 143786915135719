import { create } from "zustand";
import { getCustomFields } from "../services/customFieldsService";

interface CustomFieldsState {
  customFields: any[];
  getCustomFields: () => any;
}

const useCustomFieldsStore = create<CustomFieldsState>((set) => ({
  customFields: [],
  getCustomFields: async () => {
    console.log("store");
    const response = await getCustomFields();
    set({ customFields: response });
  },
}));

export default useCustomFieldsStore;
