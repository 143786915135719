import axios from "axios";
import { User } from "../model/user";

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

const csrfTokenElement = document.querySelector('meta[name="csrf-token"]');
const csrfToken = csrfTokenElement ? csrfTokenElement.getAttribute('content') : null;

export const getUsers = async() =>  {
  return await axios
    .get(`/users.json`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error getting resource:", error);
    });
};

export const addUser = async(user: User) =>  {
  return await axios
    .post(`/newusers`, user,{
      headers: {
        'X-CSRF-Token': csrfToken
      }
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error creating resource:", error);
    });
};

export const deleteUser = (user_id: number) =>  {
  return axios
    .delete(`/users/${user_id}`, {
      headers: {
        'X-CSRF-Token': csrfToken
      }
    })

}

export const updateUser = async(user: User) =>  {
    return await axios
        .put(`/users/${user.id}`, user, {
          headers: {
            'X-CSRF-Token': csrfToken
          }
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.error("Error updating resource:", error);
        });
};

