import React, { useState, useEffect } from "react";
import { CheckboxField, FormField, useFormContext } from "@shared/form_fields";

export default function ApiKeysForm() {
  const [confirmed, setConfirmed] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const { result, error, submitted, enable, disable, action, close } = useFormContext();

  // handles enabling/disabling the form based on whether
  // a key has been shown and whether the user has confirmed copying the key
  useEffect(() => {
    if (confirmed) {
      enable();
    } else if (submitted) {
      disable();
    }

    if (!submitted) {
      setConfirmed(false);
    }
  }, [confirmed, submitted]);

  //*** Rendering logic

  // The normal initial form
  if (!submitted || error || action == "edit") {
    return <>
        <FormField field="name" required={true} />
      </>
  }

  // The secret key display form that shows after submission
  return <div className="mt-5">
    <label for="id" className="block mb-1 font-semibold">ID:</label>
    <div id="id" className="text-lg">{result.id}</div>

    <div className="mt-2">
      <label for="secret" className="block mt-3 mb-1 font-semibold">Secret Key:</label>
      <div id="secret" className="inline-block text-blue-700 text-lg">{result.secret}</div>
      <button type="button" onClick={() => {
            navigator.clipboard.writeText(result.secret);
            setTooltipVisible(true);
            setTimeout(() => setTooltipVisible(false), 2000);
        }} className="align-bottom ml-4 p-1 rounded border border-gray-700">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z" />
          </svg>
      </button>
      {tooltipVisible && <span className="ml-2 text-sm text-gray-700">
        Copied
      </span>}
    </div>

    <div className="text-sm bg-red-50 text-red-800 mt-4 p-3 rounded">
      <p>Note: The secret key will not be shown again!</p>
      <p>Please store it in a safe place.</p>
    </div>

    <div className="mt-5">
      <input id="confirm_secret" type="checkbox"
        className={"cursor-pointer"}
        checked={confirmed}
        onChange={(e) => setConfirmed(e.target.checked)}
      />
      <label htmlFor="confirm_secret" className={`pl-2 inline-block select-none cursor-pointer`}
        onClick={(e) => {
          e.preventDefault();
          setConfirmed(!confirmed);
        }
      }>
        I have stored the secret key to a safe place
      </label>
    </div>

  </div>
}

