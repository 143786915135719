import React from "react";
import { CommonProps } from "../props/CommonProps";
import Modal from "./Modal";
import Typography from "./Typography";

interface ModalProps extends CommonProps {
  open: boolean;
  title?: string;
  onClose: any;
  content?: string;
}

export const ResultModal = ({
  open = false,
  title,
  onClose,
  content,
}: ModalProps) => {
  return (
    <Modal
      isConfirmationModal={true}
      cancelText="Ok"
      open={open}
      onClose={onClose}
    >
      <Typography
        paragraph={true}
        sx={{ textAlign: "center", marginTop: "20px" }}
        size={"26px"}
        children={title}
      ></Typography>
      <Typography
        size={"14px"}
        paragraph={true}
        sx={{ textAlign: "center" }}
        children={content}
      ></Typography>
    </Modal>
  );
};

export default ResultModal;
