import axios from "axios";
import { CustomField } from "components/model/customField";

axios.defaults.headers.common["Accept"] = "application/json";

const csrfTokenElement = document.querySelector('meta[name="csrf-token"]');
const csrfToken = csrfTokenElement
  ? csrfTokenElement.getAttribute("content")
  : null;

const getCustomFields = async () => {
  return await axios
    .get(`/custom_fields.json`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error getting resource:", error);
    });
};

export const addCustomField = async(customField: CustomField) =>  {
  delete customField.id;
  return await axios
      .post(`/custom_fields`, customField, {
        headers: {
          'X-CSRF-Token': csrfToken
        }
      })
      .then((response) => {
          return response.data;
      })
      .catch((error) => {
          console.error("Error creating resource:", error);
      });
};

export const updateCustomField = async (customField: any) => {
  return await axios
    .put(`/custom_fields/${customField.id}`, customField, {
      headers: {
        "X-CSRF-Token": csrfToken,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error updating resource:", error);
    });
};

export const deleteCustomField = async (id: number) => {
  return await axios
    .delete(`custom_fields/${id}`, {
      headers: {
        'X-CSRF-Token': csrfToken
      }
    })
}

export { getCustomFields };
