import MuiButton from "@mui/material/Button";
import React from "react";
import { CommonProps } from "../props/CommonProps";
import { Box, CircularProgress } from "@mui/material";

interface ButtonProps extends CommonProps {
  variant?: any;
  endIcon?: any;
  component?: any;
  startIcon?: any;
  disabled?: boolean;
  cirularProgress?: boolean;
}

export const Button = ({
  variant = "outline",
  className,
  children,
  component = "",
  onClick,
  sx,
  disabled,
  endIcon,
  cirularProgress = false,
}: ButtonProps) => {
  return (
    <MuiButton
      variant={variant}
      sx={[sx, { fontWeight: "700" }]}
      className={className}
      onClick={onClick}
      disabled={disabled}
      component={component}
      endIcon={endIcon}
    >
      {cirularProgress && (
        <Box sx={{ display: "flex" }}>
          <CircularProgress
            sx={{
              width: "20px !important",
              height: "20px !important",
              color: "white",
              mr: "10px",
            }}
          />
        </Box>
      )}
      {children}
    </MuiButton>
  );
};

export default Button;
