import React from "react";
import { FormField, CheckboxField, SelectField } from "@shared/form_fields";

export default function HolidayListForm({}) {
  return (
    <>
      <FormField field="name" />
    </>
  )
}
