import React from "react";
import { FormModalLink, ShowFormModalButton, EditFormModalButton, NewFormModalButton } from "@shared/modal";
import { DeleteButton } from "@shared/form_fields";
import { TableEmpty, Column } from "@shared/tables/table";
import { AutoTable } from "@shared/tables/auto_table";
import Flash from "@shared/flash";
import { Card } from "@shared/card";
import { useRailsContext } from "@shared/utils";

export default function UsersIndex({users}) {
  const { current_user } = useRailsContext();

  return (
    <>
      <Flash />

      <div className="flex justify-between items-center">
        <h1 className="font-semibold text-2xl text-slate-700">
          Users
        </h1>

      </div>

      <Card className="mt-3 pb-6">
        <div id="users" className="flex justify-between p-8">
          <div>
            <span>Search here</span>
          </div>
          <div>
            <NewFormModalButton className="pl-3" label="Invite User" title="Invite User"/>
          </div>
        </div>

        <AutoTable data={users} sortBy="id"> {/* sortBy={sortBy} sortOrder={sortOrder} onSort={(sortBy, sortOrder) => { setSortBy(sortBy); setSortOrder(sortOrder); }} loading={loading}> */}
          <TableEmpty>
            <p className="p-6 text-gray-900"><i>No users created yet</i></p>
          </TableEmpty>

          <Column title="Name" field="full_name">
            {user => <>
                <FormModalLink instance={user} label={user.full_name} action="show" />
                {user.id === current_user.id &&
                  <span className="ml-2 py-1 px-3 text-xs rounded-full font-semibold bg-green-100 text-green-800">You</span>
                }
              </>
            }
          </Column>
          <Column title="Email" field="email"/>
          <Column title="Master Admin" field="master_admin">
            {user => user.master_admin ? "Yes" : "No"}
          </Column>
          <Column sortable={false} style={{width: "1%"}}>
            {user =>
              <div className="flex gap-3 items-center">
                <EditFormModalButton instance={user} className="w-24" />
                {current_user.id !== user.id &&
                  <DeleteButton instance={user} className="w-24" />
                }
              </div>
            }
          </Column>

        </AutoTable>
      </Card>
    </>
  )
}
