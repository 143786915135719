import React from "react";

function toCamelCase(str) {
  return str
    .replace(/\s(.)/g, function (match) {
      return match.toUpperCase();
    })
    .replace(/\s/g, "")
    .replace(/^(.)/, function (match) {
      return match.toLowerCase();
    });
}

const FormLabel = ({ label }) => {
  return (
    <label htmlFor={toCamelCase(label)} style={{ marginBottom: 0 }} className="font-semibold">
      {label}
    </label>
  );
};
export default FormLabel;
