import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { CommonProps } from "../props/CommonProps";

interface AutocompleteProps extends CommonProps {
  onChange?: (value: any) => void;
  name?: string;
  data: any;
  label?: string;
  value?: string;
  selectedValue?: number;
  className?: string;
  labelTop?: boolean;
  disabled?: boolean;
  sx?: any;
}

const Dropdown = ({
  name = "",
  data,
  onChange,
  label,
  value = "id",
  selectedValue,
  disabled,
  className,
  labelTop = false,
  sx,
}: AutocompleteProps) => {
  const [target, setTarget] = useState(selectedValue ? selectedValue : "");

  useEffect(() => {
    setTarget(selectedValue);
  }, [selectedValue]);

  const handleChange = (event: SelectChangeEvent) => {
    setTarget(event.target.value);
    onChange(event.target.value);
  };

  return (
    <FormControl disabled={disabled} fullWidth className={className}>
      <>
        {data && data?.length === 0 && (
          <InputLabel className={`!top-[${labelTop ? "-8px" : "0"}]`}>
            {label ? label : "none created"}
          </InputLabel>
        )}
        <Select value={target} onChange={handleChange} sx={sx} name={name}>
          {data
            ?.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
            .map((el, index) => {
              return (
                <MenuItem key={index} value={el[value]}>
                  {el["name"]}
                </MenuItem>
              );
            })}
        </Select>
      </>
    </FormControl>
  );
};

export default Dropdown;
