import React from "react";
import { CommonProps } from "../props/CommonProps";
import MuiTypography from "@mui/material/Typography";

interface TypographyProps extends CommonProps {
  color?: any;
  size: any;
  weight?: any;
  paragraph?: boolean;
}

export const Typography = ({
  color,
  children,
  size,
  weight,
  paragraph,
  sx,
}: TypographyProps) => {
  return (
    <MuiTypography
      sx={sx}
      fontWeight={weight}
      fontSize={size}
      paragraph={paragraph}
      aria-label="typography"
    >
      {children}
    </MuiTypography>
  );
};

export default Typography;
