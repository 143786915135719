import React from "react"
const Preview = () => {

    return(
        <svg width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.2694 10.9667C11.372 10.9667 12.3083 10.5807 13.0783 9.80891C13.8483 9.03709 14.2333 8.09987 14.2333 6.99725C14.2333 5.89464 13.8474 4.95833 13.0756 4.18833C12.3038 3.41833 11.3665 3.03333 10.2639 3.03333C9.1613 3.03333 8.225 3.41925 7.455 4.19109C6.685 4.96291 6.3 5.90013 6.3 7.00275C6.3 8.10536 6.68592 9.04167 7.45775 9.81167C8.22957 10.5817 9.1668 10.9667 10.2694 10.9667ZM10.2612 9.61333C9.53373 9.61333 8.91722 9.35873 8.41167 8.84952C7.90611 8.34029 7.65333 7.72196 7.65333 6.99452C7.65333 6.26706 7.90794 5.65056 8.41715 5.145C8.92638 4.63944 9.54471 4.38667 10.2722 4.38667C10.9996 4.38667 11.6161 4.64127 12.1217 5.15048C12.6272 5.65971 12.88 6.27804 12.88 7.00548C12.88 7.73294 12.6254 8.34944 12.1162 8.855C11.607 9.36056 10.9886 9.61333 10.2612 9.61333ZM10.2667 14C7.99556 14 5.94222 13.3544 4.10667 12.0633C2.27111 10.7722 0.902222 9.08444 0 7C0.902222 4.91556 2.27111 3.22778 4.10667 1.93667C5.94222 0.645556 7.99556 0 10.2667 0C12.5378 0 14.5911 0.645556 16.4267 1.93667C18.2622 3.22778 19.6311 4.91556 20.5333 7C19.6311 9.08444 18.2622 10.7722 16.4267 12.0633C14.5911 13.3544 12.5378 14 10.2667 14ZM10.2627 12.6C12.1476 12.6 13.8794 12.0906 15.4583 11.0717C17.0372 10.0528 18.2389 8.69556 19.0633 7C18.2389 5.30444 17.0385 3.94722 15.4623 2.92833C13.886 1.90944 12.1554 1.4 10.2706 1.4C8.38576 1.4 6.65389 1.90944 5.075 2.92833C3.49611 3.94722 2.28667 5.30444 1.44667 7C2.28667 8.69556 3.4948 10.0528 5.07106 11.0717C6.64733 12.0906 8.37789 12.6 10.2627 12.6Z" fill="#2F6E8C"/>
        </svg>

    )
}

export default Preview;