import React from "react";
import LoginFlowCard from "../components/login-flow-card";
import { Button } from "@shared/form_fields";
import loginFlowMessages from "../utils/login-flow-messages.json";

const ConfirmationSuccess = ({ onResend }) => {
  const successMessage =
    loginFlowMessages.en.devise.confirmations.send_instructions;

  return (
    <LoginFlowCard
      icon={
        <i className="fa fa-envelope xx text-sky-600 text-[8rem] text-center"></i>
      }
    >
      <div className="flex flex-col gap-4 items-center text-center">
        <h1 className="text-3xl font-bold">Check your email</h1>
        <p>{successMessage}</p>

        <div className="flex flex-col gap-2 w-full">
          <Button
            primary
            onClick={() => location.replace(`/users/sign_in`)}
          >
            Continue to login
          </Button>
          <Button secondary onClick={onResend}>
            Resend confirmation instructions
          </Button>
        </div>
      </div>
    </LoginFlowCard>
  );
};
export default ConfirmationSuccess;
