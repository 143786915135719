import { createContext } from "react";

export interface RailsContextParams {
  current_user: {
    id: number;
    email: string;
    full_name: string;
    master_admin: boolean;
  }
  current_organization: {
    id: number;
    name: string;
  }
  rails_env: string;
  controller_name: string;
  model_name: string;
  action_name: string;
  current_path: string;
  current_url: string;
  csrf_token: string;
  flash: any;
  query_params: any;
  devise_errors: any;
  image_paths: {
    [filename: string]: string;
  }
  entity_name: {
    singular: string;
    plural: string;
  }
}

export const RailsContext = createContext<RailsContextParams | null>(null);
