import React from "react";
import Flash from "@shared/flash";
import { Card } from "@shared/card";
import { IndexLink } from "@shared/html_tags";
import { useObjectState } from "@shared/hooks";
import { useAjax } from "@shared/ajax";
import { AutoTable } from "@shared/tables/auto_table";
import { Column, TableEmpty } from "@shared/tables/table";
import { format, toZonedTime } from "date-fns-tz";
import { EditFormModalButton, NewFormModalButton } from "@shared/modal";
import { DeleteButton } from "@shared/form_fields";
import { organizationUrlFor } from "@shared/utils";

export default function HolidayListShow({holiday_list: data}) {
  const [params, setParams] = useObjectState({
    sortBy: "date",
    sortOrder: "asc",
    page: 1,
    perPage: 50,
    search: ""
  });
  const { sortBy, sortOrder, page, perPage, search } = params;
  const ajax = useAjax(data);
  const { data: holiday_list } = ajax;

  return (
    <>
      {/* <Flash /> */}

      <div className="flex justify-between items-center">
        <h1 className="font-semibold text-2xl text-slate-700">
        <span className="text-gray-500">Holiday List:</span> {holiday_list.name}
        </h1>
      </div>

      <IndexLink to="holiday_lists" className="block mt-1 mb-4 font-normal text-sm">&larr; All Holiday Lists</IndexLink>

      <Card className="mt-3 pb-6">
        <div className="flex justify-between px-8 py-6">
          <div className="flex grow">
            {/* <Search value={search} items={regular_schedules.map((prompt) => prompt.name)} onChange={(search) => setParams({ search })} className="z-10 w-4/5 md:w-3/5" /> */}
          </div>

          <div>
            <NewFormModalButton className="pl-3" label="New Holiday" ajax={true} onSubmit={ajax.refresh} closeOnSubmit={true}
              model="holiday" template="holidays/form" formAction={organizationUrlFor("holidays")}
              props={{ holiday_list_id: holiday_list.id }}
            />
          </div>
        </div>

        <AutoTable data={holiday_list.holidays} loading={ajax.loading}
          sortBy={sortBy} sortOrder={sortOrder}
          onSortChanged={(sortBy, sortOrder) => { setParams({ sortBy, sortOrder }) }}
          currentPage={page} pageSize={perPage}
          onPageChanged={page => setParams({ page })}
        >
          <TableEmpty>
            <p className="p-6 text-gray-900"><i>No holidays found.</i></p>
          </TableEmpty>

          <Column title="Date" field="date" sortField="date">
            {holiday => format(toZonedTime(new Date(holiday.date), "UTC"), "MM/dd/yyyy")}
          </Column>
          <Column title="Name" field="name" blankValue="-" />

          <Column sortable={false} style={{ width: "1%" }}>
            {holiday =>
              <div className="flex gap-3 items-center">
                {/* You'll want to check for actual permissions for this */}
                <EditFormModalButton instance={holiday} className="w-24"
                  ajax={true} onSubmit={ajax.refresh} closeOnSubmit={true}
                  props={{ holiday_list_id: holiday_list.id }}
                />

                <DeleteButton instance={holiday} className="w-24" ajax={true} onDelete={ajax.refresh} />
              </div>
            }
          </Column>

        </AutoTable>
      </Card>
    </>
  )
}
