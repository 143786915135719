import React, { useState, Fragment } from 'react'
import { Spinner } from '@shared/spinners'
import { ListBox } from '@shared/list_box';
import { filterChildrenByType, findChildByType } from '@shared/utils';
import { useScrollDirection } from '@shared/hooks';
import { Transition } from "@headlessui/react";

const TableContext = React.createContext({});
const RowContext = React.createContext({});

export const Table = ({data, children, loading, sortBy: initialSortBy, sortOrder: initialSortOrder, onSort}) => {
  const [sortBy, setSortBy] = useState(initialSortBy || "id");
  const [sortOrder, setSortOrder] = useState(initialSortOrder || "asc");

  const { ref: scrollRef, hasLeftScroll, hasRightScroll } = useScrollDirection(20);

  // Used by the table headers to sort the table
  const handleSort = (header) => {
    if (!header.sortable) return;

    const newSortBy = header.sortField || header.field;
    setSortBy(newSortBy);
    // If the same header is clicked, toggle the sort order
    // Otherwise, default to ascending
    const newSortOrder = sortBy == newSortBy && sortOrder == "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);

    onSort && onSort({ by: newSortBy, order: newSortOrder });
  };

  // Used for the mobile view sort dropdowns
  const handleSortByChange = (header) => {
    if (sortBy == (header.sortField || header.field)) return;
    handleSort(header);
  };
  const handleSortOrderChange = (order) => {
    if (sortOrder == order) return;

    setSortOrder(order);
    onSort && onSort({ by: sortBy, order: order });
  };

  const columns = filterChildrenByType(children, Column);
  const headers = columns.map(column => {
    return {
      title: column.props.title,
      field: column.props.field,
      sortField: column.props.sortField,
      sortable: column.props.sortable === undefined ? true : column.props.sortable,
      sortOrder: sortBy == column.props.field ? sortOrder : null
    }
  });
  const sortByHeader = headers.find(header => header.field === sortBy);
  const emptyComponent = findChildByType(children, TableEmpty) || <DefaultTableEmpty />;
  const empty = !data || data?.length === 0;

  return <TableContext.Provider value={{empty: empty, loading: loading}}>
      <div className="relative">

        {/* Sort dropdowns for mobile view */}
        <div className="md:hidden flex gap-3 items-center mx-6 mb-4">
          <label>Sort:</label>
          <ListBox value={sortByHeader} label={sortByHeader?.title} onChange={handleSortByChange} options={headers.filter(header => header.sortable)} />
          <ListBox value={sortOrder} label={sortOrder} onChange={handleSortOrderChange} options={["asc", "desc"]} />
        </div>

        { /* Loading spinner */}
        {loading && <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-80 flex justify-center items-center z-10" role="status">
          <div className="md:ml-36 fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <Spinner size="3xl" ariaLabel="Loading..." />
          </div>
        </div>}

        { /* Table */}
        <div className="shadow overflow-x-auto overflow-y-hidden z-0" ref={scrollRef}> { /* allows horizontal scrolling on overflow */}

          <div className={`pointer-events-none h-[calc(100%_-_1px)] w-24 absolute top-0 left-0 bg-gradient-to-r from-white from-20% to-transparent z-10 transition-opacity ease-in-out duration-300 ${hasLeftScroll ? "opacity-100" : "opacity-0"}`}></div>
          <div className={`pointer-events-none h-[calc(100%_-_1px)] w-24 absolute top-0 right-0 bg-gradient-to-l from-white from-20% to-transparent z-10 transition-opacity ease-in-out duration-300 ${hasRightScroll ? "opacity-100" : "opacity-0"}`}></div>

          <table className="min-w-full md:table">
            <thead className="bg-slate-100 text-slate-600  md:table-header-group hidden border-b border-b-slate-400">
              <tr className="md:table-row">
                {headers.map((header, index) =>
                  <th key={index} className={`${header.sortable && "cursor-pointer hover:text-slate-800 hover:border-b hover:border-slate-500"} font-semibold text-sm px-4 py-3 leading-normal text-left relative whitespace-nowrap md:table-cell select-none first:md:pl-8 last:md:pr-8 ${header.sortOrder && "bg-slate-200 text-slate-800 border-b border-slate-500"}`}
                    onClick={() => handleSort(header)}
                  >
                    <span>{header.title}</span> <span className={`inline-block mb-[2px] ml-4 text-[8px] align-middle ${!header.sortOrder && "opacity-0"}`}>
                      {header.sortOrder == "asc" ? "▲" : "▼"}
                    </span>
                  </th>
                )}
              </tr>
            </thead>
            <tbody className="block md:table-row-group ">
              {data?.map((row, rowIndex) =>
                <RowContext.Provider value={row} key={row.id || rowIndex}>
                  <tr className="block md:table-row border-gray-300 border-b md:border-slate-200 p-2 md:p-0">
                    {columns.map((column, columnIndex) =>
                      <td key={columnIndex} style={column.props.style} className={"px-3 py-3 text-gray-900 whitespace-nowrap md:table-cell md:border-none block md:px-3 first:md:pl-8 last:md:pr-8"} >
                        {headers[columnIndex].title &&
                          <label className="md:hidden mr-1 font-semibold text-slate-800">{headers[columnIndex].title}: </label>
                        }
                        {column}
                      </td>
                    )}
                  </tr>
                </RowContext.Provider>
              )}

              {empty && !loading &&
                emptyComponent
              }
              {empty && loading &&
                <TableEmpty>
                  <p className="p-6 text-gray-900"><i>Loading...</i></p>
                </TableEmpty>
              }
            </tbody>
          </table>

        </div>
      </div>
  </TableContext.Provider>
}

export const TableEmpty = ({children}) => {
  const tableContext = React.useContext(TableContext);

  return <>
    {tableContext.empty === true &&
      <tr>
          <td>
            {children}
          </td>
      </tr>
    }
  </>
}

export const DefaultTableEmpty = () => {
  return <TableEmpty>
    <p className="p-6 text-gray-900"><i>No data</i></p>
  </TableEmpty>
}

export const Column = ({children = null, title = null, field = null, blankValue = "", sortable = true, ...props}) => {
  const row = React.useContext(RowContext);

  const hasChildren = React.Children.count(children) > 0;
  const isFunction = typeof children === 'function';

  if (isFunction) {
    return children(row);
  }
  if (hasChildren) {
    return children;
  }
  return row[field] || blankValue || "";
}
