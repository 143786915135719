import React, { useEffect, useRef } from "react";
import { CsrfToken } from "@shared/form_fields";

export default function UserDropdown({ current_user }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef}>
      <button
        className="cursor-pointer border border-gray-600 hover:border-primary-400 hover:text-primary-500 text-black font-medium rounded-md py-1 pl-4 pr-3 bg-white"
        onClick={() => { setIsOpen(!isOpen); }}
      >
        <span className="mr-1">{current_user.full_name}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="w-5 h-5 inline-block align-text-bottom"
        >
          <path
            fillRule="evenodd"
            d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      <UserDropDownMenu isOpen={isOpen} />
    </div>
  );
}

const UserDropDownMenu = ({ isOpen }) => {
  return (
    <div className={`${!isOpen && "hidden"} absolute right-0 mt-2 w-52 py-1 bg-white border rounded-lg shadow-lg`}>

      <a href="/users/edit" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">
        Edit Profile
      </a>

      <form method="post" action="/users/sign_out">
        <input type="hidden" name="_method" value="delete" autoComplete="off" />
        <CsrfToken />
        <button type="submit" className="block px-4 py-2 text-gray-800 hover:bg-gray-100 text-left w-full">
          Sign Out
        </button>
      </form>

      <form className="button_to" method="post" action="/users/auth/webex_cc?myparam=heyyy+there">
        <CsrfToken />
        <button type="submit" className="block px-4 py-2 text-gray-800 hover:bg-gray-100 text-left w-full">
          Add WxCC Account
        </button>
      </form>

    </div>
  );
}
