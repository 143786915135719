import React from "react";
import { EditFormModalButton } from "@shared/modal";
import { DeleteButton } from "@shared/form_fields";
import { TableEmpty, Column } from "@shared/tables/table";
import { AutoTable } from "@shared/tables/auto_table";
import Flash from "@shared/flash";
import { Card } from "@shared/card";

export default function ApplicationSettingsIndex({application_settings}) {
  return (
    <>
      <Flash />

      <div className="flex justify-between items-center">
        <h1 className="font-semibold text-2xl text-slate-700">
          Settings
        </h1>

      </div>

      <Card className="mt-3 pb-6">
        <div id="settings" className="flex justify-start p-6">
          <div>
            <span>Search here</span>
          </div>
        </div>

        <AutoTable data={application_settings} sortBy="id">
          <TableEmpty>
            <p className="p-6 text-gray-900"><i>No settings created yet</i></p>
          </TableEmpty>

          <Column title="Setting" field="key"/>

          <Column title="Value" field="value" sortField="value">
            {setting => String(setting.value)}
          </Column>

          <Column sortable={false} style={{width: "1%"}}>
            {setting =>
              <div className="flex gap-3 items-center">
                <EditFormModalButton instance={setting} action={setting.id ? "edit" : "new"} title="Edit Setting" submitButtonLabel="Save" />
                {setting.id &&
                  <DeleteButton instance={setting} dontConfirm={true} label={"Reset to Default"} />
                }
              </div>
            }
          </Column>

        </AutoTable>

      </Card>
    </>
  )
}
