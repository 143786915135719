import MuiAutocomplete from "@mui/material/Autocomplete";
import React from "react";
import { CommonProps } from "../props/CommonProps";
import InputText from "./InputText";
import { twMerge } from "tailwind-merge";

interface AutocompleteProps extends CommonProps {
  options?: any;
  open?: boolean;
  loading?: boolean;
  size?: any;
  label?: string;
  onChange?: any;
  sx?: any;
  variant?: string;
  value?: any;
}

export const Autocomplete = ({
  options = [],
  sx,
  open,
  label = "Type to search",
  loading,
  onChange,
  value,
  className,
  variant = "standard",
  size = "small",
}: AutocompleteProps) => {
  return (
    <MuiAutocomplete
      size={size}
      value={value}
      loading={loading}
      className={className}
      onChange={(val: any) => onChange(val)}
      sx={sx}
      options={options}
      renderInput={(params) => (
        <InputText
          variant={variant}
          onChange={(val: any) => onChange(val)}
          params={params}
          label={label}
        />
      )}
    />
  );
};

export default Autocomplete;
