import React from "react";
import { FormField } from "@shared/form_fields";

export default function OrganizationsForm() {
  return (
    <>
      <FormField field="name" />
      <FormField field="time_zone" />
    </>
  )
}
