import { useState, useEffect, useRef } from 'react';
import { useEffectAfterMount, useRailsContext } from './utils';
import { Sort, Paging } from './filter_sort_paginate';

type UseAjaxWrapperOptions = {
  params?: any,
  nested?: boolean
}

type UseAjaxResource = {
  url: string
} | string

// export function useAjaxGet (resource: UseAjaxResource, options: UseAjaxWrapperOptions = {}) {
//   return useAjax(resource, options);
// }

/**
 * A utility hook to fetch data using AJAX.
 * It can either take a path string directly or an object with the initial data.
 * If the initial data is provided, it will not fetch new data until you call the refresh function, or the path or params change.
 * If the initial data is not provided, it will fetch data on the first render and whenever the path or params change.
 * The Rails CRSF token is automatically added to the request headers.
 *
 * @param resource - Path string or object with initial data.
 * - If an object is provided, it should look the same as the API response and include a "url" property with the path to fetch the data.
 * @param options - Options object
 * @param options.params - Object with query parameters to be sent with the request
 * @param options.nested - If true, and a URL string is provided, the URL will be prefixed with the current organization ID
 * @returns Object with the following properties:
 * - data: The fetched data
 * - refresh: Function to re-fetch the data
 * - updateItem: Function to update a single item in the list
 * - loading: Boolean indicating if the data is being fetched
 *
 * @example
 * const { data, refresh, updateItem, loading } = useAjax("/users");
 * @example
 * const { data, refresh, updateItem, loading } = useAjax({ url: "/users", items: [{ id: 1, name: "John" }] });
 *
 */
export function useAjax(resource: UseAjaxResource, options: UseAjaxWrapperOptions = {}) {
  const { current_organization, csrf_token, controller_name, model_name } = useRailsContext();
  const {
    params,
    nested = true
  } = options;

  // The first parameter can be either a string representing path to the resource or an object with the initial data
  // The object is what we get from the server when rendering the page and should look the same as the API response
  let initialData;
  let url : string;
  if (typeof resource === "string") {
    url = resource as string;
    if (current_organization && nested) {
      url = `/${current_organization.id}/${url}`;
    } else if (!url.startsWith("/")) {
      url = `/${url}`;
    }
  } else {
    url = resource.url;
    initialData = resource;
  }

  const [data, setData] = useState<{ items: any[] }>(initialData || {});
  // If initialData is not set, we start in a loading state since we need to fetch the data first
  const [loading, setLoading] = useState(initialData === undefined);


  async function fetchData(params) {
    setLoading(true);

    // Build the query string from provided params
    const queryParams = new URLSearchParams();
    if (params) {
      Object.keys(params).forEach(key => {
        if (params[key] instanceof Date) {
          queryParams.set(key, params[key].toISOString());
        } else if (typeof params[key] === "object") {
          queryParams.set(key, JSON.stringify(params[key]));
        } else {
          queryParams.set(key, params[key]);
        }
      });
    }

    const response = await fetch(url + "?" + queryParams.toString(), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "X-CSRF-Token": csrf_token,
        "X-Requested-With": "XMLHttpRequest",
        "Cache-Control": "no-cache"
      }
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    setData(await response.json());
    setLoading(false);
  }

  // Allows updating a single item without re-fetching the entire list
  // Assumes the format of the data is { items: [{}, {}, ...] }, like { "items": [{ id: 1, name: "John" }] }
  // Only works if this is a list of items coming from an index action
  const replaceItem = (item) => {
    const index = data?.items?.findIndex((i) => i.id === item.id);
    if (index !== -1) {
      const newData = { ...data };
      newData.items[index] = item;
      setData(newData);
    }
  }

  const update = async (record, data, onSubmit) => {
    setLoading(true);

    const method = "PATCH";
    const path = record.url || `/${current_organization.id}/${controller_name}/${record.id}`;

    let body = {};
    body[record.__model_name || model_name] = data;

    try {
      const response = await fetch(path, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "X-CSRF-Token": csrf_token,
        },
        body: JSON.stringify(body)
      });
      const result = await response.json();

      if (!response.ok) {
        console.warn(`Error submitting via ajax: ${response.status} - ${response.statusText}`, result);
        setLoading(false);
        // setError(result);
        // setSubmitted(true);
        return;
      }

      replaceItem(result);
      setLoading(false);
      // setSubmitted(true);
      // setResult(result);
      if (onSubmit) {
        onSubmit(result);
      }


    } catch (error) {
      console.error("Error submitting via ajax", error);
      setLoading(false);
      // setError(error);
      // setSubmitted(true);
    }
  }

  // If initialValue is set, we don't want to fetch data on first render.
  // Only when the url or params change.
  // Otherwise, we fetch data on first render.
  if (initialData == undefined) {
    useEffect(() => {
      fetchData(params);
    }, [url, JSON.stringify(params)]);
  } else {
    useEffectAfterMount(() => {
      fetchData(params);
    }, [url, JSON.stringify(params)]);
  }

  return {
    data,
    refresh: () => fetchData(params),
    update,
    loading
  }
}
