import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
    className?: string;
}
const TabPanel = (props: TabPanelProps) => {
    const { children, className, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            className={`w-full ${className}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    );
}

export default TabPanel;