import React, { useState } from "react";
import { FormField, CheckboxField, SelectField, useFormContext, Checkbox, HiddenField, ToggleField } from "@shared/form_fields";
import { format, toZonedTime } from "date-fns-tz";
import MiniCalendar from "@shared/mini_calendar";

export default function SpecialScheduleForm({schedule_id}) {
  const { instance } = useFormContext();
  const [timeDisabled, setTimeDisabled] = useState(instance.closed_all_day);

  let initialDate = instance.date ? new Date(instance.date) : new Date();
  const [date, setDate] = useState(format(toZonedTime(initialDate, "UTC"), "yyyy/MM/dd"));

  return (
    <>
      <HiddenField field="schedule_id" value={schedule_id} />

      <MiniCalendar selectedDate={date} onChange={newDate => setDate(format(toZonedTime(newDate, "UTC"), "yyyy/MM/dd"))} />
      <HiddenField field="date" value={date} />

      <div className="flex gap-2">
        <FormField field="start_time" type="time" className="grow" readOnly={timeDisabled}
          value={format(toZonedTime(new Date(instance.start_time || "2000-01-01T00:00:00.000Z"), "UTC"), "HH:mm")}
        />
        <FormField field="end_time" type="time" className="grow" readOnly={timeDisabled}
          value={format(toZonedTime(new Date(instance.end_time || "2000-01-01T23:59:59.999Z"), "UTC"), "HH:mm")}
        />
      </div>

      <ToggleField field="closed_all_day" label="Closed All Day" className="mt-2" onChange={setTimeDisabled} />
    </>
  )
}


