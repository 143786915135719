import React from "react";

const LoginFlowLinks = ({ currentView }) => {
  return (
    <div className="flex flex-col gap-0">
      {currentView !== "login" && (
        <a href="/users/sign_in" className="text-sky-700 underline">
          Back to login
        </a>
      )}

      {currentView !== "new-password" && (
        <a href="/users/password/new" className="text-sky-700 underline">
          Forgot your password?
        </a>
      )}

    </div>
  );
};
export default LoginFlowLinks;
