import MuiBreadcrumbs from "@mui/material/Breadcrumbs";
import React from "react";
import { CommonProps } from "../props/CommonProps";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

interface BreadcrumbsProps extends CommonProps {
  breadcrumbs: any;
}

export const Breadcrumbs = ({ breadcrumbs, sx }: BreadcrumbsProps) => {
  return (
    <MuiBreadcrumbs
      sx={sx}
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      {breadcrumbs}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
