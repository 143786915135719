import React, { useState } from "react";
import { twMerge } from "tailwind-merge";

export const Tabs = ({ children, className }) => {
  const [activeTab, setActiveTab] = useState(0);

  const headers = React.Children.map(children, (child, index) => {
    return {
      title: child.props.title,
      className: child.props.className
    };
  });

  return <>
    <ul role="tablist" className={twMerge("border-b-[1px] last:border-b-primary-100", className)}>
      {headers.map((header, index) =>
        <li key={index} className={twMerge(`${(index == activeTab) ? "border-b-primary-500 text-primary-500 border-b-[2px]" : "hover:border-b-primary-200 text-gray-500 hover:border-b-[2px]"} font-semibold text-lg md:min-w-20 relative mb-[-1px] inline-block p-4 md:pb-3 md:px-8 md:pt-4 cursor-pointer text-center`, header.className)}
          onClick={() => setActiveTab(index)} >
          {header.title}
        </li>
      )}
    </ul>
    {children[activeTab] || children}
  </>;
}

export const Tab = ({ children }) => {
  return <div>{children}</div>;
}
