import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import Modal from "../layout/Modal";
import { ReturnPluralValues } from "../constants/AppplicationNames";
import useOrganizationStore from "../store/organizationStore";
import { useRailsContext } from "@shared/utils";

const MenuItemList = ({ open, props, items }) => {
  const { current_organization } = useRailsContext();
  const [openSignUpModal, setOpenSignUpModal] = useState(false);
  const application_name = useOrganizationStore(
    (state) => state.application_name
  );

  const pathName = window.location.pathname;

  return (
    <>
      <List>
        <ListItem disablePadding sx={{ display: "block" }}>
          <a href={`/${current_organization?.id}/entities`}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <Tooltip title={ReturnPluralValues(application_name)}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={require(`../../../assets/images/${pathName.includes("entities") ? "entities-icon-green.svg" : "entities-icon.svg"}`)}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={ReturnPluralValues(application_name)}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </a>
        </ListItem>
        {items.map((item, i) => {
          return (
            <ListItem
              key={`${item}-${i}`}
              disablePadding
              sx={{ display: "block" }}
            >
              <>
                <a
                  target={item !== "documentation" ? "_self" : "_blank"}
                  href={
                    item !== "documentation"
                      ? `/${current_organization?.id}/${item?.replace(" ", "_")}`
                      : `/api-documentation/`
                  }
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <Tooltip
                      title={`${
                        item?.charAt(0).toUpperCase() + item?.slice(1)
                      }`}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={require(
                            `../../../assets/images/${
                              item ? item : "entities"
                            }-icon${pathName.includes(item.replace(' ', '_')) ? "-green" : ""}.svg`
                          )}
                        />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText
                      primary={`${
                        item?.charAt(0).toUpperCase() + item?.slice(1)
                      }`}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </a>
              </>
            </ListItem>
          );
        })}
        <ListItem disablePadding sx={{ display: "block" }}>
          <a onClick={() => setOpenSignUpModal(!openSignUpModal)}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <Tooltip title="Log Out">
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={require("../../../assets/images/log-out-icon.svg")}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Log Out" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </a>
        </ListItem>
      </List>

      <Modal
        okText="Sign Out"
        content=""
        onClose={() => setOpenSignUpModal(false)}
        onSubmit={() => {
          localStorage.removeItem("organizationId");
          window.location.href = "/users/sign_out";
        }}
        open={openSignUpModal}
        title="Sign Out"
        height={15}
      >
        <p>Are you sure you want to logout?</p>
      </Modal>
    </>
  );
};

export default MenuItemList;
