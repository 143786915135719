import React from "react";
import Flash from "@shared/flash";
import { Card } from "@shared/card";
import { Tabs, Tab } from "@shared/tabs";
import { IndexLink } from "@shared/html_tags";
import { useAjax } from "@shared/ajax";
import RegularSchedulesTab from "./regular-schedules-tab";
import SpecialSchedulesTab from "./special-schedules-tab";

export default function ScheduleShow({schedule: data}) {

  const ajax = useAjax(data);
  const schedule = ajax.data;

  return (
    <>
      {/* <Flash /> */}

      <div className="flex justify-between items-center">
        <h1 className="font-semibold text-2xl text-slate-700">
          <span className="text-gray-500">Schedule:</span> {schedule.name}
        </h1>
      </div>

      <IndexLink to="schedules" className="block mt-1 mb-4 font-normal text-sm">&larr; All Schedules</IndexLink>

      <Card className="mt-3">
        <Tabs className="">

          <Tab title="Regular">
            <RegularSchedulesTab ajax={ajax} />
          </Tab>

          <Tab title="Special">
            <SpecialSchedulesTab ajax={ajax} />
          </Tab>

        </Tabs>
      </Card>
    </>
  )
}
