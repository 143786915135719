import React, { useState, useMemo, Fragment } from "react";
import { FormField, HiddenField, useFormContext } from "@shared/form_fields";
import Editor, { useMonaco } from '@monaco-editor/react';
import { Transition } from "@headlessui/react";

// import * as actions from "@monaco-editor/esm/vs/platform/actions/common/actions";

// let menus = actions.MenuRegistry._menuItems;
// let contextMenuEntry = [...menus].find(entry => entry[0]._debugName == "EditorContext");
// let contextMenuLinks = contextMenuEntry[1];

// let removableIds = ["editor.action.goToTypeDefinition"];

// let removeById = (list, ids) => {
//   let node = list._first;
//   do {
//     let shouldRemove = ids.includes(node.element?.command?.id);
//     if (shouldRemove) { list._remove(node)  }
//   } while ((node = node.next));
// };

// removeById(contextMenuLinks, removableIds);


export default function CustomFunctionForm() {
  const { instance, shown, readOnly } = useFormContext();

  const handleEditorWillMount = (monaco) => {
    // Set compiler options to exclude DOM types
    // monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
    //   noLib: true, // Disable the default library (which includes DOM types)
    //   target: monaco.languages.typescript.ScriptTarget.ESNext,
    //   allowNonTsExtensions: true
    // });
    monaco.languages.typescript.typescriptDefaults.setCompilerOptions({
      target: monaco.languages.typescript.ScriptTarget.ESNext,
      allowNonTsExtensions: true,
      moduleResolution: monaco.languages.typescript.ModuleResolutionKind.NodeJs,
      module: monaco.languages.typescript.ModuleKind.CommonJS,
      // Don't include the standard DOM library, it's not available in Deno
      lib: ["esnext", "es2015"]
    });

    // monaco.editor.onDidCreateEditor((editor) => {
    //   // Replace the existing context menu service
    //   const originalContextMenuService = editor._actions['editor.action.showContextMenu']._contextMenuService;
    //   editor._actions['editor.action.showContextMenu']._contextMenuService = {
    //     showContextMenu: function(delegate, anchor, actions, arg) {
    //       const filteredActions = actions.filter(action => {
    //         // Modify this condition to remove the actions you don't want
    //         return !action.label.includes('Peek');
    //       });
    //       // Call the original service with filtered actions
    //       originalContextMenuService.showContextMenu(delegate, anchor, filteredActions, arg);
    //     }
    //   };
    // });
    monaco.languages.typescript.typescriptDefaults.setDiagnosticsOptions({
      // Ignore "Cannot find module 'xxx'" errors, it's not really Deno aware
      diagnosticCodesToIgnore: [2307]
    });

    // Include custom type definitions
    monaco.languages.typescript.typescriptDefaults.addExtraLib(`
      type Req = {
        searchParams: { [param: string]: string }
      };
      const response = (res: string) : Response;

      const req : Req;
      `,
      'filename/fake.d.ts'
    );
  }

  const handleEditorDidMount = (editor, monaco) => {
      // editor.onContextMenu(() => {
      //   setTimeout(() => {
      //     const shadowroot = document.querySelector('.shadow-root-host')?.shadowRoot;
      //     if (shadowroot) {
      //       const RemoveContextMenuIndexes = [ 4,   10, 11, 12,   16, 17 ];
      //       for (const itemIndex of RemoveContextMenuIndexes) {
      //         const el = shadowroot.querySelector(
      //           `ul.actions-container > li.action-item:nth-child(${itemIndex})`
      //         );
      //         if (el) {
      //           el.querySelector('a')
      //             ?.querySelectorAll('span')
      //             .forEach((s) => s.remove());
      //           el.querySelector('a')?.remove();
      //         }
      //       }
      //     }
      //   }, 0);
      // });
      // editor.addAction({
      //   id: "new-cut-action",
      //   label: "Cut",
      //   keybindings: [
      //     monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyX
      //   ],
      //   precondition: null,
      //   keybindingContext: null,
      //   contextMenuGroupId: '9_cutcopypaste',
      //   contextMenuOrder: 10,
      //   run: function (ed) {
      //     ed.trigger('editor', 'cut', '');
      //   },
      // });
      // editor.addAction({
      //   id: "new-copy-action",
      //   label: "Copy",
      //   keybindings: [
      //     monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyC
      //   ],
      //   precondition: null,
      //   keybindingContext: null,
      //   contextMenuGroupId: '9_cutcopypaste',
      //   contextMenuOrder: 11,
      //   run: function (ed) {
      //     ed.trigger('editor', 'copy', '');
      //   },
      // });
      // editor.addAction({
      //   id: "new-paste-action",
      //   label: "Paste",
      //   keybindings: [
      //     monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyP
      //   ],
      //   precondition: null,
      //   keybindingContext: null,
      //   contextMenuGroupId: '9_cutcopypaste',
      //   contextMenuOrder: 12,
      //   run: function (ed) {
      //     ed.trigger('editor', 'paste', '');
      //   },
      // });
  }

  // Resets the default code when the form is hidden or reloaded
  const defaultCode = useMemo(() => {
    return instance.code || '// uses Deno (https://deno.com/), a node compatible server-side JavaScript runtime\n// import any npm library by prefixing it with "npm:", like: `import leftPad from "npm:left-pad"`\n\nasync function handler(req : Req) {\n  let res = "ok";\n  return res;\n}';
  }, [shown, instance])

  const [code, setCode] = useState(defaultCode);

  return (
    <>
      <FormField field="name" />
      <HiddenField field="code" value={code} />
      <div className="border border-gray-200">
        <Transition show={shown} as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div>
          <Editor
            height="60vh"
            defaultLanguage="typescript"
            defaultValue={defaultCode}
            onChange={(value) => setCode(value)}
            beforeMount={handleEditorWillMount}
            onMount={handleEditorDidMount}
            className={readOnly ? "opacity-[0.6]" : ""}
            options={{
              readOnly: readOnly,
              fontSize: 14,
              padding: { top: 20 },
              minimap: { enabled: false },
              overviewRulerLanes: 0, // Disables the overview ruler
            }}
          />
          </div>
        </Transition>
      </div>
    </>
  )
}
