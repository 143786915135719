import React from "react";
import { FormField, HiddenField, SelectField } from "@shared/form_fields";

export default function ApplicationSettingsForm() {
  return (
    <>
      <FormField field="key" readOnly={true} />
      <HiddenField field="key" />
      <SelectField field="value" options={["true", "false"]} />
    </>
  )
}
