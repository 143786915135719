import React, { useMemo, useState } from "react";
import { useObjectState } from "@shared/hooks";
import AudioManager from "@shared/audio-manager";
import { Search } from "@shared/search";
import { EditFormModalButton, NewFormModalButton } from "@shared/modal";
import { organizationUrlFor, useEffectAfterMount } from "@shared/utils";
import { AutoTable } from "@shared/tables/auto_table";
import { TableEmpty, Column } from "@shared/tables/table";
import { DeleteButton } from "@shared/form_fields";
import { PlayCircleIcon, StopCircleIcon } from "@heroicons/react/24/solid";
import { DownloadFilledIcon } from "@shared/icons/momentum";

const PromptsTab = ({ ajax }) => {
  const [params, setParams] = useObjectState({
    sortBy: "name",
    sortOrder: "asc",
    page: 1,
    perPage: 50,
    search: ""
  });
  const { sortBy, sortOrder, page, perPage, search } = params;
  const { data: entity } = ajax;
  const { prompts } = entity;

  const filteredPrompts = useMemo(() => {
    if (!search) return prompts;
    return prompts.filter(prompt => prompt.name.toLowerCase().includes(search.toLowerCase()));
  }, [ajax.data, search]);

  useEffectAfterMount(() => {
    AudioManager.reset();
  }, [ajax.data]);

  return <>
    <div id="prompts" className="flex justify-between px-8 py-6">
      <div className="flex grow">
        <Search value={search} items={prompts.map((prompt) => prompt.name)} onChange={(search) => setParams({ search })} className="z-10 w-4/5 md:w-3/5" />
      </div>

      <div>
        <NewFormModalButton className="pl-3" label="New Prompt" ajax={true} onSubmit={ajax.refresh} closeOnSubmit={true}
          model="prompt" template="prompts/form" formAction={organizationUrlFor("prompts")}
          props={{ entity_id: entity.id }}
        />
      </div>
    </div>

    <AutoTable data={filteredPrompts} loading={ajax.loading}
      sortBy={sortBy} sortOrder={sortOrder}
      onSortChanged={(sortBy, sortOrder) => { setParams({ sortBy, sortOrder }) }}
      currentPage={page} pageSize={perPage}
      onPageChanged={page => setParams({ page })}
    >
      <TableEmpty>
        <p className="p-6 text-gray-900"><i>No prompts found.</i></p>
      </TableEmpty>

      <Column title="Name" field="name" />
      <Column title="Description" field="description" />

      <Column title="Audio" sortable={false}>
        {prompt => <AudioControl prompt={prompt} />}
      </Column>

      <Column sortable={false} style={{ width: "1%" }}>
        {prompt =>
          <div className="flex gap-3 items-center">
            {/* You'll want to check for actual permissions for this */}
            <EditFormModalButton instance={prompt} className="w-24"
              ajax={true} onSubmit={ajax.refresh} closeOnSubmit={true}
              props={{}}
            />
            <DeleteButton instance={prompt} className="w-24" ajax={true} onDelete={ajax.refresh} />
          </div>
        }
      </Column>

    </AutoTable>
  </>
};

export default PromptsTab;

const AudioControl = ({ prompt }) => {
  const [esPlaying, setEsPlaying] = useState(false);
  const [enPlaying, setEnPlaying] = useState(false);

  AudioManager.on("ended", (src, id) => {
    setEnPlaying(false);
    setEsPlaying(false);
  });
  AudioManager.on("play", (src, id) => {
    if (id == prompt.id + "-audio_en") {
      setEnPlaying(true);
      setEsPlaying(false);
    } else if (id == prompt.id + "-audio_es") {
      setEsPlaying(true);
      setEnPlaying(false);
    } else {
      setEnPlaying(false);
      setEsPlaying(false);
    }
  });

  const play = (url, id) => {
    AudioManager.playAudio(url, prompt.id + "-" + id);
  };

  return (
    <div className="flex flex-row gap-2">

      <div className={`rounded border ${prompt.audio_en_filename ? "border-primary-600" : "border-gray-500"} w-32 text-sm px-1 inline-flex gap-2 justify-center items-center`}>

        <span className={`${prompt.audio_en_filename ? "text-primary-600" : "text-gray-500"} p-1`}>English</span>

        {prompt.audio_en_filename ?
          <>
            {!enPlaying || esPlaying ? (
              <PlayCircleIcon
                onClick={() => {
                  if (prompt.audio_en_filename) {
                    play(prompt.audio_en_url, "audio_en");
                  }
                }}
                className="fill-primary-600 inline-block h-6 cursor-pointer"
              />
            ) : (
              <StopCircleIcon
                onClick={() => {
                  if (prompt.audio_en_filename) {
                    play(prompt.audio_en_url, "audio_en");
                  }
                }}
                className="fill-orange-600 inline-block h-6 cursor-pointer"
              />
            )}

            <a target="_blank" href={prompt.audio_en_url}>
              <DownloadFilledIcon className="fill-primary-600 inline-block h-5 cursor-pointer" />
            </a>
          </>

          :

          <i className="text-gray-500 p-1">None</i>
        }

      </div>

      <div className={`rounded border ${prompt.audio_es_filename ? "border-primary-600" : "border-gray-500"} w-32 text-sm px-1 inline-flex gap-1 justify-center items-center`}>

        <span className={`${prompt.audio_es_filename ? "text-primary-600" : "text-gray-500"} p-1`}>Spanish</span>

        {prompt.audio_es_filename ?
          <>
            {!esPlaying || enPlaying ? (
              <PlayCircleIcon
                onClick={() => {
                  if (prompt.audio_es_filename) {
                    play(prompt.audio_es_url, "audio_es");
                  }
                }}
                className="fill-primary-600 inline-block h-6 cursor-pointer"
              />
            ) : (
              <StopCircleIcon
                onClick={() => {
                  if (prompt.audio_es_filename) {
                    play(prompt.audio_es_url, "audio_es");
                  }
                }}
                className="fill-orange-600 inline-block h-6 cursor-pointer"
              />
            )}

            <a target="_blank" href={prompt.audio_es_url}>
              <DownloadFilledIcon className="fill-primary-600 inline-block h-5 cursor-pointer" />
            </a>
          </>

          :

          <i className="text-gray-500 p-1">None</i>
        }

      </div>


    </div>
  );
};
